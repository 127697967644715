import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Box, Grid, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { MIconButton } from '../../../components/@mui-extend';
import Iconify from '../../../components/iconify';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// utils
import { passwordError } from '../../../utils/helpError';
import { readRefferal } from '../../../utils/refferal';

import { Api } from '../../../apis/api';
import AUTH_URLS from '../../../apis/urls/auth.urls';

async function RegisterUser(username, vid, hashcode, firstname, password, email) {
  const formData = new FormData();
  const arr = username.toString().split('-');
  const refcode = readRefferal(); 
  formData.append('authid', vid);
  formData.append('secret', hashcode);
  formData.append('name', firstname);
  formData.append('code', arr[0]);
  formData.append('phone', arr[1]);
  formData.append('email', email);
  formData.append('password', password);
  formData.append('refferal', refcode);

  const response = await Api.unpost(AUTH_URLS.register.sms, formData);
  return response.data;
}

// ----------------------------------------------------------------------
RegFinalForm.propTypes = {
  username: PropTypes.string,
  vid: PropTypes.string,
  hashcode: PropTypes.string,
};
export default function RegFinalForm({ username, vid, hashcode }) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const { register } = useAuth();

  const RegisterSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    firstname: Yup.string().required('name is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      password: '',
      email: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        HandleRegiter();

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code || error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  async function HandleRegiter() {
    const firstname = values.firstname;
    const email = values.email;
    const password = values.password;

    const response = await RegisterUser(username, vid, hashcode, firstname, password, email);
  
    if (response.status === 200) {
      await register(response.data);
    } else {
      enqueueSnackbar('User Already Exists', {
        variant: 'info',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Iconify icon={'eva:close-fill'} />
          </MIconButton>
        ),
      });
    }
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} pl={3}>
            <TextField
              fullWidth
              label="Your Name"
              {...getFieldProps('firstname')}
              error={Boolean(touched.firstname && errors.firstname)}
              helperText={touched.firstname && errors.firstname}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              sx={{ mb: 3 }}
            />
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password) || passwordError(errors.afterSubmit).error}
              helperText={(touched.password && errors.password) || passwordError(errors.afterSubmit).helperText}
              sx={{ mb: 3 }}
            />
          </Grid>

          <Box sx={{ mt: 3 }}>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" pending={isSubmitting}>
              Register
            </LoadingButton>
          </Box>
        </Form>
      </FormikProvider>
    </>
  );
}
