// AUTH
const AUTH_URLS = {
  jwtlogin: '/api/v1/co/ax/user/jwt/',
  refresh: '/api/v1/co/ax/jwt/refresh/',
  knoxlogin: '/api/v1/co/ax/user/knox/',
  profile: '/api/v1/co/sh/profile/get/',
  otp: {
    sms: '/api/v1/sg/sh/sms/get/',
    email: '/api/v1/sg/sh/email/get/',
    verify: '/api/v1/sg/sh/otp/verify/',
    resend: '/api/v1/sg/sh/email/resend/',
  },
  register: {
    emaillater: '/api/v1/co/ax/register/email-later/',
    email: '/api/v1/co/ax/register/email/',
    sms: '/api/v1/co/ax/register/sms/',
  },
  changepass: '/api/v1/co/ax/password/change/',
  reset: {
    email: '/api/v1/co/ax/reset/email/',
    sms: '/api/v1/co/ax/reset/sms/',
  },
};

export default AUTH_URLS;
