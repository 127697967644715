import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// @mui
import { Box, Link, Card, Tooltip, Grid, Avatar, Typography, Stack } from '@mui/material';
// components
// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';

// data
import { useWinners } from '../../../apis/service/web/winner';

// ----------------------------------------------------------------------

WinnerCard.propTypes = {
  item: PropTypes.object,
};

function WinnerCard({ item }) {
  const { img, name, notes } = item;

  return (
    <Card sx={{ m: 1 }}>
      <Box component="img" alt={name} src={img} sx={{ width: '100%', height: 180, objectFit: 'cover' }} />

      <Tooltip>
        <Stack sx={{ textAlign: 'left' }}>
          <Typography variant="subtitle" noWrap gutterBottom>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {notes}
          </Typography>
        </Stack>
      </Tooltip>
    </Card>
  );
}

// ----------------------------------------------------------------------

export default function ShopListWidget() {
  const [page, setPage] = useState(0);
  const { data, isLoading, error, refetch } = useWinners(page);

  useEffect(() => {
    refetch();
  }, [refetch, page]);

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  const results = data.data;
  const hasEmpty = results.length === 0;
  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h3" component={'h1'} sx={{ py: 10, color: '#000', display: 'block', textAlign: 'center' }}>
          Winners Gallery
        </Typography>

        {hasEmpty && (
          <Grid item xs={12} sx={{mx:{xs:3,md:15,lg:35}}}>
          <Box
            component={'img'}
            src={'/asset/images/web/img_3.jpg'}
            sx={{ height: 'auto', objectFit: 'cover', width:'100%', borderRadius: 5, boxShadow: 'none' }}
          />
        </Grid>
        )}
      </Grid>

      {results.map((winner) => (
        <Grid item xs={12} md={6} lg={3} key={winner.id}>
          <WinnerCard item={winner} />
        </Grid>
      ))}
    </Grid>
  );
}
