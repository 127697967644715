import { useQuery } from 'react-query';
import {filter} from 'lodash';
import {Api} from '../../api';
import WEB_URLS from '../../urls/web.urls'; 

export const useWinners = (page) => {
   return useQuery('winner-gallery', async () => {
      const response = await  Api.unget(`${WEB_URLS.coupon.winner.list}?page=${page}`);
      return response.data;
   });
  };
