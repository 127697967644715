import React from 'react';
import { motion } from 'framer-motion';
import { Box } from '@mui/material';

const Loading = () => {
  const lpath = '/asset/logo/logo.png';
  return (
    <motion.div
      initial={{ rotateY: 0 }}
      animate={{ rotateY: 360 }}
      transition={{
        duration: 2,
        ease: 'easeInOut',
        repeatDelay: 1,
        repeat: Infinity,
      }}
    >
      <Box component="img" alt={`shop festia`} src={lpath} sx={{ width: 'auto', height: 64, cursor: 'default' }} />
    </motion.div>
  );
};

export default Loading;
