import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, Stack, Card, Grid, Typography, Divider, CardContent } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import {Tick} from '../../../components/icons/svgs';
import Error from '../../../components/error';
import Loading from '../../../components/loading';
import Label from '../../../components/label';

// data
import { usePackages } from '../../../apis/service/common/package';

//
// ----------------------------------------------------------------------

const StyledName = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  fontSize: theme.spacing(3.5),
  fontWeight: 400,
  WebkitLineClamp: 2,
  textTransform: 'capitalize',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  padding: theme.spacing(0, 2),
}));

FeatureItem.propTypes = {
  item: PropTypes.object,
};

function FeatureItem({ item }) {
  const theme = useTheme();
  const { name, count, notes, active } = item;

  const textcolor = active ? theme.palette.primary.main : theme.palette.grey;

  return (
    <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{ py: 0.75, color: textcolor }}>
      <Stack direction={'row'} spacing={1} sx={{ color: textcolor }}>
              <Box>
                <Tick color={'#32cd32'} />
              </Box>
        <Typography variant="caption" sx={{ fontWeight: '600', color: textcolor }}>
          {count}
        </Typography>
        <Typography variant="caption" sx={{ color: textcolor }}>
          {name}
        </Typography>
      </Stack>

      {notes && <Tick color={theme.palette.primary.main} /> }
    </Stack>
  );
}
// ----------------------------------------------------------------------

PackageItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
};

function PackageItem({ item, index }) {
  const theme = useTheme();
  const hasPopular = index === 1;
  const { name, notes, amount, rate, offer, cfeatures } = item;

  return (
    <>
      <motion.div
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.3 }}
        sx={{
          width: '100%',
        }}
      >
        <Card
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
              border: `2.5px solid ${theme.palette.primary.main}`,
            },
          }}
        >
          <Stack direction={'column'} textAlign={'center'} spacing={1.5}>
            {hasPopular && (
              <CardContent sx={{ bgcolor: theme.palette.primary.main, color: 'common.white' }}>
                <Typography variant="body" sx={{ textTransform: 'uppercase' }}>
                  Most popular
                </Typography>
              </CardContent>
            )}
            {!hasPopular && <Box sx={{ minHeight: 70 }} />}

            <StyledName>{name}</StyledName>
            <Typography variant="caption" sx={{ color: 'common.black', minHeight:'44px' }}>
              {notes}
            </Typography>
            <Stack direction={'row'} alignContent={'center'} justifyContent={'center'} spacing={1}>
              <Typography variant="body" noWrap sx={{ textDecoration: 'line-through', color: 'gray' }}>
                ₹{rate}
              </Typography>
              <Label
                variant="filled"
                color={hasPopular ? 'info' : 'error'}
                sx={{ opacity: 0.55, borderRadius: '15px' }}
              >
                SAVE {Math.floor(offer)}%
              </Label>
            </Stack>

            <Stack
              direction={'row'}
              alignContent={'center'}
              textAlign={'center'}
              justifyContent={'center'}
              spacing={1}
              sx={{ color: '#F45', py: 2 }}
            >
              <Typography variant="h6" sx={{ pt: 1, fontWeight: 300 }}>
                ₹
              </Typography>
              <Typography variant="h3" noWrap>
                {Math.floor(amount)}.00
              </Typography>
            </Stack>
            <Box mb={2}>
              <Button
                to={'/shop-register'}
                size="small"
                variant="contained"
                component={RouterLink}
                sx={{ mx: theme.spacing(2), px: theme.spacing(4) }}
              >
                Buy Now
              </Button>
            </Box>

            <Stack direction={'column'} spacing={1.5} sx={{ p: 2 }}>
              <Divider variant="middle" light textAlign="center">
                TOP FEATURES
              </Divider>
              {cfeatures.map((feature, index) => (
                <FeatureItem key={index} item={feature} />
              ))}
            </Stack>
          </Stack>
        </Card>
      </motion.div>
    </>
  );
}
// ----------------------------------------------------------------------

export default function PackageWidget() {
  const theme = useTheme();
  const { data, isLoading, error } = usePackages();

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }

  return (
    <Stack direction={'column'} sx={{ pb: 10 }}>
      <Typography
        variant="h3"
        sx={{ color: 'common.black', py: 5, textAlign: 'center', textTransform: 'capitalize' }}
      >
        Select your membership package
      </Typography>
      <Grid container spacing={theme.spacing(3)}>
        {data.map((draw, index) => (
          <Grid item key={`p${index}`} xs={12} sm={6} lg={3}>
            <PackageItem item={draw} index={index} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
