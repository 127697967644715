import { useQuery } from 'react-query';
import { filter } from 'lodash';
import { Api } from '../../api';
import TNT_URLS from '../../urls/tweb.urls'; 


export const useProducts = (shopcode, searchtext) => {
  return useQuery('tenant-products', async () => {
    // return filter(PRODUCTS, (_item) => _item.name.toLowerCase().includes(searchtext));
    const response = await  Api.unget(`${TNT_URLS.web.base.item.showcase}${shopcode}/?q=${searchtext}`);
    return response.data;
  });
};

// 'https://jsonplaceholder.typicode.com/posts'
