import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
// material
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { MIconButton } from '../../../components/@mui-extend';
import Iconify from '../../../components/iconify';
// hooks
// import useIsMountedRef from '../../../hooks/useIsMountedRef';

// apis
import { Api } from '../../../apis/api';
import AUTH_URLS from '../../../apis/urls/auth.urls';

// data
import { COUNTRIES } from '../../../apis/data/countries';

async function SENDSMSOTP(country, phone, method) {
  // const body=JSON.stringify({country,phone});
  const formData = new FormData();
  formData.append('code', country);
  formData.append('phone', phone);
  formData.append('method', method);
  const response = await Api.unpost(AUTH_URLS.otp.sms, formData);
  return response;
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
ResetFirstForm.propTypes = {
  setStep: PropTypes.func,
  setVid: PropTypes.func,
  setUsername: PropTypes.func,
};
export default function ResetFirstForm({ setStep, setVid, setUsername }) {
  // const isMountedRef = useIsMountedRef();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const ResetSMSSchema = Yup.object().shape({
    country: Yup.string().min(1, 'Too Short!').max(3, 'Too Long!').required('Country Code is required'),
    phonenumber: Yup.string().min(8, 'Too Short!').max(10, 'Too Long!').required('Phone number is required'),
  });

  const formik = useFormik({
    initialValues: {
      country: '91',
      phonenumber: '',
    },
    validationSchema: ResetSMSSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        console.log(values);
        HandleOtp();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  async function HandleOtp() {
    const country = values.country;
    const phonenumber = values.phonenumber;
    if (country.trim().length < 2) {
      enqueueSnackbar('Fill Fields', {
        variant: 'info',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Iconify icon={'eva:close-fill'} />
          </MIconButton>
        ),
      });
    }
    if (phonenumber.trim().length !== 10) {
      enqueueSnackbar('Enter Valied Number', {
        variant: 'info',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Iconify icon={'eva:close-fill'} />
          </MIconButton>
        ),
      });
    }
    const response = await SENDSMSOTP(country, phonenumber, 'reset');
    if (response.status === 404) {
      enqueueSnackbar('This Number is not registered, Please Register', {
        variant: 'info',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Iconify icon={'eva:close-fill'} />
          </MIconButton>
        ),
      });
    } else {
      const { vid } = response.data;
      let username = country + '-' + phonenumber;
      setUsername(username);
      setVid(vid);
      // setOtpId(response.data);
      setStep(2);
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              select
              label={t('com.country')}
              {...getFieldProps('country')}
              SelectProps={{ native: true }}
              error={Boolean(touched.country && errors.country)}
              helperText={touched.country && errors.country}
              sx={{ mb: 3, width:'200px' }}
            >
              {COUNTRIES.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.label + option.phone}
                </option>
              ))}
            </TextField>

            <TextField
              fullWidth
              label="Phone Number"
              {...getFieldProps('phonenumber')}
              error={Boolean(touched.phonenumber && errors.phonenumber)}
              helpertext={touched.phonenumber && errors.phonenumber}
              sx={{ ml: 1, mb: 3 }}
            />
          </Box>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            loadingIndicator="Loading..."
          >
            Get Otp
          </LoadingButton>
        </Grid>
      </Form>
    </FormikProvider>
  );  
}
