import { useState } from 'react';

// @mui
import { Typography, TextField, Grid, Stack } from '@mui/material';
// components

// data
//
import ShopListWidget from './shoplist';

// ----------------------------------------------------------------------

export default function FindShopWidget() {
  const [searchText, setSearchText] = useState('');

  const handleInputSearch = (e) => {
    e.preventDefault();
    const query = e.target.value;
    setSearchText(query.toLowerCase());
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3" component={'h1'} sx={{ py: 2, color: '#000', display: 'block', textAlign: 'center' }}>
          Find Shop
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <TextField fullWidth label="Search shop or area" sx={{ mb: 3 }} onChange={handleInputSearch} />
        </Stack>
      </Grid>
        <ShopListWidget searchQuery={searchText} />
    </Grid>
  );
}
