import { useQuery } from 'react-query';
import {filter} from 'lodash';
import {Api} from '../../api';
import WEB_URLS from '../../urls/web.urls'; 


export const useArea = () => {
   return useQuery('web-areas', async () => {
      const response = await Api.unget(WEB_URLS.base.area.list);
      return response.data;
   });
  };

  export const useFindShops = (searchtext) => {
   return useQuery('web-findshops', async () => {
      // return filter(
      //    SHOPS,
      //    (_item) =>
      //      _item.name.toLowerCase().includes(searchtext) ||
      //      _item.area.name.toLowerCase().includes(searchtext)
      //  );
      const response = await Api.unget(`${WEB_URLS.client.findshops}${searchtext}`);
      return response.data;
   });
  };
