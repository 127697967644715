// AUTH
const COMMON_URLS = {
  user: {
    profile: '/api/v1/co/auth/profile/get/',
  },
  auth: {
    providers: '/api/v1/ag/sh/auth/list/',
  },
  payment: {
    providers: '/api/v1/pg/sh/pgs/list/',
  },
  plan: {
    membership: 'api/v1/pl/sh/package/membership/',
    booster: 'api/v1/pl/sh/package/booster/',
  },
};

export default COMMON_URLS;
