import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useState, useRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Grid, Typography, CardContent } from '@mui/material';
// components

import Error from '../../../components/error';
import Loading from '../../../components/loading';
import { CarouselControlsArrowsBasic2 } from '../../../components/carousel/controls';

// utils
import { imgLazy } from '../../../utils/imageLazy';

// data
import { useAdvtBanners } from '../../../apis/service/web/home';

//
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object,
};

function CarouselItem({ item }) {
  const { img, name, alttext } = item;

  return (
    <>
      <Box
        component="img"
        alt={alttext}
        src={imgLazy(img)}
        sx={{ width: '100%', height: { xs: 'calc(width/3', md: '40%', lg: 'calc(width/3' }, objectFit: 'cover' }}
      />
    </>
  );
}

function CarouselView({ banners }) {
  const theme = useTheme();
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(2);

  const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    fade: Boolean(theme.direction !== 'rtl'),
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Card sx={{ margin: 0, padding: 0, borderRadius: 0 }}>
      <Slider ref={carouselRef} {...settings}>
        {banners.map((item) => (
          <CarouselItem key={item.id} item={item} />
        ))}
      </Slider>
    </Card>
  );
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AdvtBannerWidget() {
  const theme = useTheme();
  const { data, isLoading, error } = useAdvtBanners();

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <Grid container sx={{ pt: theme.spacing(5) }}>
      <Grid item xs={12} sm={4} md={4}>
        <CarouselView banners={data.a} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <CarouselView banners={data.b} />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <CarouselView banners={data.c} />
      </Grid>
    </Grid>
  );
}
