// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_CLIENT = '/s';

// ----------------------------------------------------------------------

export const PATH_CLIENT = {
  root: ROOTS_CLIENT,
  home: path(ROOTS_CLIENT, '/:shopcode'),
  about: path(ROOTS_CLIENT, '/about-us'),
  products: path(ROOTS_CLIENT, '/products'),
};

export default PATH_CLIENT;
