// @mui
import { styled, alpha } from '@mui/material/styles';
import { ListItemIcon, ListItemButton, ListSubheader } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

export const StyledHomeItem = styled((props) => <ListItemButton  {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 64,
  position: 'relative',
  textTransform: 'capitalize',
  // color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  marginLeft:theme.spacing(3),
  marginRight:theme.spacing(3),
}));



export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledListSubheader = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    color: theme.palette.text.primary
  })
);

export const ActiveRootStyle =(({ theme }) => ({ 
  color: 'primary.main',
  fontWeight: 'fontWeightMedium',
  bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  '&:before': { display: 'block' }
}));


export const ActiveSubStyle = {
  color: 'text.primary',
  fontWeight: 'fontWeightMedium'
};

