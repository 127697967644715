import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Divider, DialogActions } from '@mui/material';
// hooks
// components
import Logo from '../../components/logo';

// APP Name
import { AppName } from '../../config';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '90vh',
}));

const StyledHead = styled(DialogActions)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 380,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(4, 0),

  [theme.breakpoints.up('md')]: {
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
  },
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Helmet>
        <title>
          {t('auth.login')} | {AppName}
        </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Logo disabledLink sx={{ width: 'auto', height: 120 }} />

            <Typography variant="h4" gutterBottom>
              {t('auth.w-login.welcome')}
            </Typography>

            <Divider sx={{ my: 3 }}>
              <StyledHead>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('auth.login')}
                </Typography>
                {/* <Tooltip title={open ? 'password' : 'pin'}>
                  <IconButton onClick={handleOpen} sx={{ mr: 3 }}>
                    <Iconify
                      icon={open ? 'ic:baseline-password' : 'ic:baseline-security'}
                      color={'#eab9'}
                      width={24}
                      height={24}
                    />
                  </IconButton>
                </Tooltip> */}
              </StyledHead>
            </Divider>

            <LoginForm />

            
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
