// @mui
import { Box, Grid, Typography } from '@mui/material';
// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';
import Markdown from '../../../components/markdown';
// data
import { useAbout } from '../../../apis/service/client/home';

// ----------------------------------------------------------------------

export default function AboutWidget() {
  const { data, isLoading, error } = useAbout();

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <Box mx={4}>
      <Typography variant="h2" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
        About us
      </Typography>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Markdown>{data.body}</Markdown>
      </Grid>
    </Box>
  );
}
