import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

// utils
import { isValidToken, setTokens } from '../utils/jwt';
import { LoginApiCall, LogoutApiCall, ProfileApiCall } from '../apis/service/auth/jwtapi';

// ----------------------------------------------------------------------
const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
};
// ----------------------------------------------------------------------

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  initialize: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

// ----------------------------------------------------------------------
AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [authState, setauthState] = useState(initialState);

  // const loginMutation = useMutation(LoginApiCall);
  const logoutMutation = useMutation(LogoutApiCall);
  // const profileMutation = useMutation(ProfileApiCall);

  useEffect(() => {
  
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken && isValidToken(accessToken)) {
        const data = await ProfileApiCall();
        const  user = data;
        setauthState({ ...authState, isInitialized: true, isAuthenticated: true, user });
      } else {
        setauthState({ ...authState, isInitialized: true, isAuthenticated: false, user: null });
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const login = async (username, password) => {
    try {
      const data = await LoginApiCall(username, password);
      const { user, access, refresh } = data;
      setauthState({ ...authState, isAuthenticated: true, user });
      setTokens(access, refresh);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const register = async (data) => {
    try {
      const { user, access, refresh } = data;
      setauthState({ ...authState, isAuthenticated: true, user });
      setTokens(access, refresh);
    } catch (error) {
      console.error('Register failed:', error);
    }
  };

  const logout = async () => {
    try {
     await logoutMutation.mutateAsync();
      setauthState({ ...authState, isAuthenticated: false, user: null });
      setTokens(null, null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return <AuthContext.Provider value={{ ...authState, method: 'jwt', initialize, login, logout, register }}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };

// ----------------------------------------------------------------------
