import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Checkbox,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MIconButton } from '../../../components/@mui-extend';
// components
import Iconify from '../../../components/iconify';
//
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// data
import { usePackages } from '../../../apis/service/common/package';

import PackageWidget from './PackageWidget';
import AuthRegisterForm from './AuthRegisterForm';

// ----------------------------------------------------------------------

function FetchPackages() {
  const { data } = usePackages();
  if (!data) {
    return [];
  }
  return data;
}

export default function PayForm() {
  const navigate = useNavigate();
  const theme = useTheme();

  const { isAuthenticated, status } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const packages = FetchPackages();

  const defaultPackage = packages.length > 0 ? packages[0].id : '';

  const [selectedPlan, setSelectedPlan] = useState(defaultPackage);

  const FormSchema = Yup.object().shape({
    email: Yup.string().required('Email  is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: FormSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
       

        if (isAuthenticated) {
          enqueueSnackbar('Login success', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </MIconButton>
            ),
          });
        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        // enqueueSnackbar('User name or password is incorrect.', {
        //   variant: 'error',
        //   action: (key) => (
        //     <MIconButton size="small">
        //       <Iconify icon={'eva:close-fill'} />
        //     </MIconButton>
        //   )
        //   });
        // console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.code || error.message });
        }
      }
      if (status === 403) {
        // enqueueSnackbar('You have no permission to access', {
        //   variant: 'error',
        //   action: (key) => (
        //    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        //      <Iconify icon={'eva:close-fill'} />
        //    </MIconButton>
        //  )
        //  });
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleSelectPack = (value) => {
    setSelectedPlan(value);
  };

  const PaymentForm = () => {
    const currentPlan = packages.find((item) => item.id === selectedPlan);
    return (
      <Card sx={{ mt: 5, mx: 6, p: 2 }}>
        {currentPlan && (
          <Stack direction={'column'} spacing={1} py={3}>
            <Stack direction={'row'} justifyContent={'space-between'} spacing={1} px={2}>
              <Typography
                variant="h6"
                noWrap
                sx={{ color: 'common.black', textAlign: 'left', textTransform: 'capitalize' }}
              >
                {currentPlan.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Typography
                variant="body2"
                noWrap
                sx={{ color: 'common.gray', textAlign: 'left', textDecoration: 'line-through' }}
              >
                ₹ {currentPlan.rate}
              </Typography>
              <Typography variant="h5" noWrap sx={{ color: 'common.black', textAlign: 'left' }}>
                ₹{Math.floor(currentPlan.amount)}.00
              </Typography>
            </Stack>
          </Stack>
        )}
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
          }}
        >
          <TextField
            fullWidth
            autoComplete="first_name"
            type="text"
            label="first name"
            {...getFieldProps('first_name')}
            error={Boolean(touched.first_name && errors.first_name)}
            helperText={touched.first_name && errors.first_name}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            autoComplete="last_name"
            type="text"
            label="last name"
            {...getFieldProps('last_name')}
            error={Boolean(touched.last_name && errors.last_name)}
            helperText={touched.last_name && errors.last_name}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            autoComplete="phone"
            type="text"
            label="Phone"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            autoComplete="country"
            type="text"
            label="Country"
            {...getFieldProps('country')}
            error={Boolean(touched.country && errors.country)}
            helperText={touched.country && errors.country}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            autoComplete="state"
            type="text"
            label="State"
            {...getFieldProps('state')}
            error={Boolean(touched.state && errors.state)}
            helperText={touched.state && errors.state}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            autoComplete="city"
            type="text"
            label="City"
            {...getFieldProps('city')}
            error={Boolean(touched.city && errors.city)}
            helperText={touched.city && errors.city}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            autoComplete="address"
            type="text"
            label="Address"
            {...getFieldProps('address')}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            autoComplete="zipcode"
            type="text"
            label="Zip code"
            {...getFieldProps('zipcode')}
            error={Boolean(touched.zipcode && errors.zipcode)}
            helperText={touched.zipcode && errors.zipcode}
            sx={{ mb: 3 }}
          />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
          }}
        >
          <TextField
            fullWidth
            autoComplete="company"
            type="text"
            label="company name"
            {...getFieldProps('company')}
            error={Boolean(touched.company && errors.company)}
            helperText={touched.company && errors.company}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            autoComplete="gst"
            type="text"
            label="GST Number"
            {...getFieldProps('gst')}
            error={Boolean(touched.gst && errors.gst)}
            helperText={touched.gst && errors.gst}
            sx={{ mb: 3 }}
          />
          
        </Box>
        <TextField
            fullWidth
            autoComplete="caddress"
            type="text"
            label="Company Address"
            {...getFieldProps('caddress')}
            error={Boolean(touched.caddress && errors.caddress)}
            helperText={touched.caddress && errors.caddress}
            sx={{ mb: 3 }}
          />

        <Divider />
        Tax & fees Total
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
          }}
        >
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Submit Secure Payment
          </LoadingButton>
        </Box>
      </Card>
    );
  };

  return (
    <>
     <AuthRegisterForm />
     <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PackageWidget packages={packages} selectedItem={`${selectedPlan}`} onHandle={handleSelectPack} />
          </Grid>
          <Grid item xs={12} md={8}>
            <PaymentForm />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
     </>
    
  );
}
