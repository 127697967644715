import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik, Form, FormikProvider } from 'formik';

// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, Stack, Card, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { ActionStatus, TextField } from '../../../components/form-controls';

import Error from '../../../components/error';
import Loading from '../../../components/loading';
import { Icon, Icons } from '../../../components/icons';

// data
import { useHardRead, AddHardScan } from '../../../apis/service/user/scan';

// ----------------------------------------------------------------------
const dfl = 'cup.scan.f.';

// ----------------------------------------------------------------------

function AddItem(reqid, keycode, couponcode, values) {
  const { data, status, statusText } = AddHardScan(reqid, keycode, couponcode, values);
  return <ActionStatus status={status} statusText={statusText} data={data} />; // </ActionStatus>
}

// ----------------------------------------------------------------------

ScanWidget.propTypes = {
  keycode: PropTypes.string,
  couponcode: PropTypes.string,
};

export default function ScanWidget({keycode, couponcode }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data, isLoading, error } = useHardRead(keycode, couponcode);


  const FormSchema = Yup.object().shape({
    name: Yup.string().required(t('word.f-required', { f: t('com.name') })),
    email: Yup.string().required(t('word.f-required', { f: t('com.email') })),
    phone: Yup.string().required(t('word.f-required', { f: t('com.phone') })),
    vchno: Yup.string().required(t('word.f-required', { f: t(`${dfl}vchno`) })),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      vchno: '',
    },
    validationSchema: FormSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        AddItem(reqid, keycode, couponcode, values);
        resetForm();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  
  const {reqid} = data;
  return (
    <>
      <Typography variant="h1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
        Fill Details
      </Typography>

      <Grid container sx={{ pt: theme.spacing(3) }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                autoComplete="cp name"
                type="text"
                label={t(`${dfl}name`)}
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                sx={{ mb: 3 }}
              />

              <TextField
                fullWidth
                autoComplete="cp phone"
                type="text"
                label={t(`${dfl}phone`)}
                {...getFieldProps('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                sx={{ mb: 3 }}
              />

              <TextField
                fullWidth
                autoComplete="cp email"
                type="text"
                label={t(`${dfl}name`)}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                sx={{ mb: 3 }}
              />
              <TextField
                fullWidth
                type="text"
                label={t(`${dfl}vchno`)}
                {...getFieldProps('vchno')}
                error={Boolean(touched.vchno && errors.vchno)}
                helperText={touched.vchno && errors.vchno}
                sx={{ mb: 3 }}
              />
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} loadingIndicator="Loading...">
                {t('func.submit')}
                <Icon icon={Icons.save} width={18} height={18} sx={{ ml: 0.5 }} />
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Grid>
    </>
  );
}
