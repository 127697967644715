import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useState, useRef } from 'react';
// @mui
import { useTheme, styled, alpha } from '@mui/material/styles';
import { Box, Card, Grid, Typography, CardContent, Avatar } from '@mui/material';

// utils
import { bgBlur } from '../../../utils/cssStyles';
import { imgLazy } from '../../../utils/imageLazy';

// components
import { CarouselControlsArrowsBasic2 } from '../../../components/carousel/controls';


// data
import { useMainBanner, useClients } from '../../../apis/service/web/home';

//
// ----------------------------------------------------------------------
const StyledClient = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow:1,
  position: 'absolute',
  bottom: 0,
  zindex:9,
  py: 1.5,
  mx: 'auto',
  maxWidth: '100%',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: alpha(theme.palette.grey[100], 0.38),
//  ...bgBlur({ color: theme.palette.background.default }),
  '& .slick-current div':{
    transform: 'scale(0.7)',
    transition: 'transform .3s cubic-bezier(.4, 0, .2, 1)'
  }
  
}));
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const LargeImgStyle =styled('img')(({ theme }) => ({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
 
}));
LargeItem.propTypes = {
  item: PropTypes.object
};

function LargeItem({ item }) {
  const { img, alttext } = item;

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        paddingTop: {
          xs: '60%',
          md: '40%',
        },
      }}
    >
      <LargeImgStyle alt={alttext} src={imgLazy(img)} />
    </Box>
  );
}
// ----------------------------------------------------------------------

ClientItem.propTypes = {
  item: PropTypes.object,
};

function ClientItem({ item }) {
  const { logo, name } = item;

  return (
      <Avatar src={imgLazy(logo)} alt={name} sx={{ opacity: 1,m:1 }} />
      
  );
}
// <Box component="img" alt={name} src={logo} sx={{ objectFit: 'scale-down' }} /> 

function FetchBanners() {
  const { data, isLoading, error } = useMainBanner();
  if (!data && isLoading) {
    return [];
  }
  if (!data && error) {
    return [];
  }
  return data;
}

function FetchClients() {
  const { data, isLoading, error } = useClients();

  if (!data && isLoading) {
    return [];
  }
  if (!data && error) {
    return [];
  }
  return data;
}
// ----------------------------------------------------------------------
CarouselView.propTypes = {
  banners: PropTypes.array,
  clients: PropTypes.array,
};
function CarouselView({ banners, clients }) {
  const theme = useTheme();
  const carouselRef = useRef();
  //const carouselRef2 = useRef();
  const [currentIndex, setCurrentIndex] = useState(2);

  const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    fade: Boolean(theme.direction !== 'rtl'),
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const settings2 = {
    speed: 400,
    autoplaySpeed: 2,
    dots: false,
    arrows: false,
    autoplay: true,
    centerMode: false,
    swipeToSlide: true,
    variableWidth: true,
    centerPadding: '0px',
    initialSlide: 1,
    infinite: true,
    slidesToScroll: clients.length > 1 ? 1 : clients.length,
    slidesToShow: clients.length > 5 ? 5 : clients.length,
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Card sx={{mt:2}}>
      <Slider ref={carouselRef} {...settings}>
        {banners.map((item) => (
          <LargeItem key={item.id} item={item} />
        ))}
      </Slider>

      <CarouselControlsArrowsBasic2
        index={currentIndex}
        total={banners.length}
        onNext={handleNext}
        onPrevious={handlePrevious}
        sx={{ bottom: 'calc(height/2' }}
      />

      <StyledClient      >
        <Slider {...settings2}>
          {clients.map((item) => (
            <ClientItem key={item.slug} item={item} />
            
          ))}
        </Slider>
      </StyledClient>
    </Card>
  );
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function MainBannerWidget() {

  const banners = FetchBanners();
  const clients = FetchClients();
  return (<CarouselView banners={banners} clients={clients} />);

  // return (
  //   <Grid container sx={{mt:3}}>      
  //     <CarouselView banners={banners} clients={clients} />
  //   </Grid>
  // );
}
