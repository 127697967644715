import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, RadioGroup, Stack, Card, Grid, Typography } from '@mui/material';
// components

// ----------------------------------------------------------------------

const StyledName = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  fontSize: theme.spacing(2.5),
  fontWeight: 300,
  WebkitLineClamp: 2,
  textTransform: 'capitalize',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  padding: theme.spacing(0, 2),
}));

// ----------------------------------------------------------------------

AuthItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  onHandle: PropTypes.func,
  selectedItem: PropTypes.string,
};

function AuthItem({ item, index, onHandle, selectedItem }) {
  const theme = useTheme();
  const { id, label, icon } = item;
  const onHandleClick = () => {
    onHandle(id);
  };

  const imagePath = `asset/icons/auth/${icon}`;
  const isSelected = id === selectedItem;

  return (
    <>
      <motion.div
        whileHover={{ scale: 1.01 }}
        transition={{ duration: 0.3 }}
        sx={{
          width: '100%',
        }}
      >
        <Card
          onClick={onHandleClick}
          sx={{
            p: 1,
            ...(isSelected && { border: `solid 3px ${theme.palette.primary.main}` }),
            '&:hover': {
              boxShadow: theme.shadows[13],
            },
          }}
        >
          <Stack direction={'row'} justifyContent={'center'} spacing={1}>
            <Box component="img" alt={label} src={imagePath} sx={{ width: 80, height: 40, objectFit: 'scale-down' }} />
            <StyledName>{label}</StyledName>
          </Stack>
        </Card>
      </motion.div>
    </>
  );
}
// ----------------------------------------------------------------------
AuthWidget.propTypes = {
  selectedItem: PropTypes.string,
  onHandle: PropTypes.func,
  authmethods: PropTypes.array,
};

export default function AuthWidget({ authmethods, selectedItem, onHandle }) {
  const theme = useTheme();

  return (
    <RadioGroup row value={selectedItem}>
      <Grid container spacing={theme.spacing(3)}>
        {authmethods.map((authmethod, index) => (
          <Grid item key={`au-${index}`} xs={12}>
            <AuthItem item={authmethod} index={index} onHandle={onHandle} selectedItem={selectedItem} />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  );
}
