import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useState, useRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Card, Grid } from '@mui/material';


// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';
import { CarouselControlsArrowsBasic2 } from '../../../components/carousel/controls';

// utils
import { imgLazy } from '../../../utils/imageLazy';


// data
import { useMainBanner } from '../../../apis/service/client/home';

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.object,
};

function CarouselItem({ item }) {
  const { img, title } = item;
  return (
    <>
    
      <Box component="img" alt={title} src={imgLazy(img)} sx={{ width: '100%', height: 470, objectFit: 'cover' }} />
    </>
  );
}

// ----------------------------------------------------------------------
CarouselView.propTypes = {
  banners: PropTypes.array,
};

function CarouselView({ banners }) {
  const theme = useTheme();
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(2);
  const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    fade: Boolean(theme.direction !== 'rtl'),
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Card>
      <Slider ref={carouselRef} {...settings}>
        {banners.map((item) => (
          <CarouselItem key={item.title} item={item} />
        ))}
      </Slider>

      <CarouselControlsArrowsBasic2
        index={currentIndex}
        total={banners.length}
        onNext={handleNext}
        onPrevious={handlePrevious}
        sx={{ bottom: 120 }}
      />
    </Card>
  );
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
BannerWidget.propTypes = {
  shopcode: PropTypes.string,
};
export default function BannerWidget({shopcode}) {
  const { data, isLoading, error } = useMainBanner(shopcode);

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  const banners = data ? data : [];

  return (
    <Grid container>
      <CarouselView banners={banners} />
    </Grid>
  );
}
