import { useQuery } from 'react-query';
import { Api } from '../../api';
import AUTH_URLS from '../../urls/auth.urls';

export const LoginApiCall = async (username, password) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
    const response = await Api.unpost(AUTH_URLS.jwtlogin, formData);
    return response.data;

};

export const LogoutApiCall = async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
};

export const ProfileApiCall = async () => {
    const response = await Api.get(AUTH_URLS.profile);
    return response.data;
};
