
const iconTable = 'bi:table'
const icGrid = 'eva:grid-fill'
const icChart = 'eva:pie-chart-2-fill';
const icMore = 'eva:more-vertical-fill';
const icNew = 'eva:plus-fill';
const icPlus = 'eva:plus-fill';
const icMinus = 'eva:minus-fill';
const icClose = 'eva:close-circle-outline';
const icShow = 'eva:more-horizontal-fill';
const icPrint = 'eva:printer-outline';
const icTrash = 'eva:trash-2-outline';
const icitemSearch = 'fluent:box-search-24-regular';
const icuserSearch = 'ri:user-search-line';
const icScan = 'mdi:line-scan';
const icLoading = 'eos-icons:loading';
const icAmount = 'mdi:counter';
const icPercentage = 'mdi:counter';
const icReset = 'eva:refresh-outline';
const icSave = 'eva:save-outline'
const icEdit = 'eva:more-vertical-fill';

const icons={
    table:iconTable,
    grid:icGrid,
    chart:icChart,
    more:icMore,
    new: icNew,
    plus:icPlus,
    minus:icMinus,
    close:icClose,
    show:icShow,
    print:icPrint,
    trash:icTrash,
    itemSearch:icitemSearch,
    userSearch:icuserSearch,
    scan:icScan,
    loading:icLoading,
    amount:icAmount,
    percentage: icPercentage,
    reset: icReset,
    save: icSave,
    edit: icEdit


}
  

export default icons;