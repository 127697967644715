// component
import SvgColor from '../../../components/svg-color';

import PATH_USER from '../../../routes/path/user.path';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    subheader: 'dashboard',
    items: [
      {
        title: 'dashboard',
        path: PATH_USER.dashboard,
        icon: icon('ic_analytics'),
      },
      {
        title: 'coupons',
        path: PATH_USER.coupons,
        icon: icon('ic_user'),
      },
      {
        title: 'requests',
        path: PATH_USER.requests,
        icon: icon('ic_user'),
      },
      {
        title: 'scratch cards',
        path: PATH_USER.scratchcard,
        icon: icon('ic_user'),
      },
      {
        title: 'profile',
        path: PATH_USER.profile,
        icon: icon('ic_cart'),
      },

    ],
  },
];

export default navConfig;
