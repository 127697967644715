import { jwtDecode } from "jwt-decode";
// import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';
import {tokenBeaer} from '../config';

import {setToken, removeToken, setRefreshToken, removeRefreshToken} from './token';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return  decoded.exp > currentTime;
};

 const handleTokenExpired = (exp) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  console.log(timeLeft);
  expiredTimer = window.setTimeout(() => {
    console.log('expired');
    // You can do what ever you want here, like show a notification
  }, timeLeft);
};

const setTokens = (accessToken, refreshToken) => {
  if (accessToken) {
    setToken(accessToken);
    axios.defaults.headers.common.Authorization = `${tokenBeaer}  ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    handleTokenExpired(exp);
  } else {
    removeToken();
    delete axios.defaults.headers.common.Authorization;
  }
  if(refreshToken){
    setRefreshToken(refreshToken);
  }
  else{
    removeRefreshToken()
  }
};

const setBaseTokens = (accessToken, refreshToken) => {
    setToken(accessToken);
    setRefreshToken(refreshToken);
 
};




export { isValidToken, setTokens, setBaseTokens };


/*
const setTokens = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem('access_token', accessToken);
    axios.defaults.headers.common.Authorization = `${tokenBeaer}  ${accessToken}`;
    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('access_token');
    delete axios.defaults.headers.common.Authorization;
  }
  if(refreshToken){
    localStorage.setItem('refresh_token', refreshToken);
  }
  else{
    localStorage.removeItem('refresh_token');
  }
};

*/