import { useQuery } from 'react-query';
import {Api} from '../../api';
import COMMON_URLS from '../../urls/common.urls'; 

export const useAuthMethods = () => {
   return useQuery('com-auth-options', async () => {
      const response = await  Api.unget(COMMON_URLS.auth.providers);
      return response.data;
    });
  };

