// AUTH

const API_V1 = '/api/v1/';

function ur(root, app, sublink) {
  return `${root}${app}${sublink}`;
}

const WEB_URLS = {
  pro: {
    banner: {
      main: ur(API_V1, 'adv', '/sh/banner/main/'),
      advt1: ur(API_V1, 'adv', '/sh/banner/advt/'),
    },
    partners: ur(API_V1, 'adv', '/sh/partner/list/'),
  },
  coupon: {
    scan: {
      hard: ur(API_V1, 'cou', '/sh/scan/'),
      verify: ur(API_V1, 'cou', '/sh/scan/'),
    },
    draw: {
      upcomming: ur(API_V1, 'cou', '/sh/draw/upcomming/'),
      completed: ur(API_V1, 'cou', '/sh/draw/completed/'),
    },
    winner: {
      list: ur(API_V1, 'cou', '/sh/winner/list/'),
    },
  },
  client: {
    domain: {
      check: ur(API_V1, 'cli', '/sh/domain/check/?q='), // ?q=api&d=xd.com
    },
    shop:{
      shopbycode: ur(API_V1, 'cli', '/sh/shop/getbycode/'),
    },
    clients: ur(API_V1, 'cli', '/sh/client/list/'),
    findshops: ur(API_V1, 'cli', '/sh/shop/find/?q='),
  },
  cms: {
    counter: ur(API_V1, 'cms', '/sh/counter/list/'),
    content: {
      privacy: ur(API_V1, 'cms', '/sh/content/get/privacy-policy/'),
      terms: ur(API_V1, 'cms', '/sh/content/get/terms-of-use/'),
      refund: ur(API_V1, 'cms', '/sh/content/get/refund-policy/'),
      fraud: ur(API_V1, 'cms', '/sh/content/get/fraud-policy/'),
      about: ur(API_V1, 'cms', '/sh/content/get/about-us/'),
    },
    faq: {
      groups: ur(API_V1, 'cms', '/sh/faq/groups/'),
      all: ur(API_V1, 'cms', '/sh/faq/all/'),
      list: ur(API_V1, 'cms', '/sh/faq/list/'), // slug/
      get: ur(API_V1, 'cms', '/sh/faq/get/'), // slug/
    },
  },
  base: {
    area: {
      list: ur(API_V1, 'bs', '/sh/area/list/'),
    },
  },
  user:{
    coupon: {
      coupons: ur(API_V1, 'cou', '/sh/coupon/list/'),
      requests: ur(API_V1, 'cou', '/sh/req/list/'),
    },
    scan: {
      hard: ur(API_V1, 'cou', '/sh/scan/'),
      verify: ur(API_V1, 'cou', '/sh/scan/'),
      req: ur(API_V1, 'cou', '/sh/scan/'),
      live: ur(API_V1, 'cou', '/sh/scan/'),
    },

  }

};

export default WEB_URLS;
