import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useParams, NavLink as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Grid, Container, Typography } from '@mui/material';
import { MHidden } from '../../components/@mui-extend';

// components
import Iconify from '../../components/iconify';

// APP Name
import { AppName } from '../../config';

// section components
import BannerWidgets from '../../sections/client/home/BannerWidgets';
import MainWidget from '../../sections/client/home/MainWidget';
import TeamWidget from '../../sections/client/home/TeamWidget';
import ProductForm from '../../sections/client/product/findproductform';

// ----------------------------------------------------------------------
const StyledRequest = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  alignTracks: 'center',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: alpha(theme.palette.grey[900], 0.92),
  color: alpha(theme.palette.grey[200], 0.92),
  textTransform: 'capitalize',
  padding: theme.spacing(1.5, 3),
}));

// ----------------------------------------------------------------------

CouponWidget.propTypes = {
  shopcode: PropTypes.string,
};

function CouponWidget({ shopcode }) {
  return (
    <Card sx={{ p: 3, my: 4 }}>
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
        }}
      >
        <motion.div whileHover={{ scale: 0.9 }} transition={{ duration: 0.5 }}>
          <StyledRequest component={RouterLink} to={'/'} sx={{maxWidth:'xs'}}>
            <Iconify icon="eva:menu-2-fill" sx={{ mr: 1 }} />
            Request Coupon
          </StyledRequest>
        </motion.div>
        <Typography variant="h6">link</Typography>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

export default function ShopPage() {
  const { shopcode } = useParams();
  return (
    <>
      <Helmet>
        <title> Client Home | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <BannerWidgets shopcode={shopcode} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <MHidden width={'mdUp'}>
              <CouponWidget shopcode={shopcode} />{' '}
            </MHidden>
            <MainWidget shopcode={shopcode} />
            <ProductForm shopcode={shopcode} />
          </Grid>
          <Grid item xs={12} md={4}>
            <MHidden width={'mdDown'}>
              <CouponWidget shopcode={shopcode} />
            </MHidden>
            <MainWidget shopcode={shopcode} />
            <TeamWidget shopcode={shopcode} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
