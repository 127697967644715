import PropTypes from 'prop-types';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Box, Card, Paper, Typography, CardHeader, CardContent } from '@mui/material';
// components
// components
import Iconify from '../../../components/iconify';
import Error from '../../../components/error';
import Loading from '../../../components/loading';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// data
import { useCounter } from '../../../apis/service/web/home';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
CounterCard.propTypes = {
  items: PropTypes.array,
};

function CounterCard({items, ...other }) {
  return (
    <Card {...other} >
      <CardContent>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          {items.map((site) => (
            <Paper key={site.name} variant="outlined" sx={{ py: 4.5, textAlign: 'center' }}>
              <Box sx={{ mb: 0.5 }}>
                <Iconify icon={site.icon} color={site.color} width={32} />
              </Box>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {fShortenNumber(site.value)}
              </Typography>

              <Typography variant="h6">{site.name}</Typography>
            </Paper>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

export default function CounterWiget() {
  const theme = useTheme();
  const { data, isLoading, error } = useCounter();

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return <CounterCard  items={data} sx={{my:theme.spacing(5)}} />;
}
