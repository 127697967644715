import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

// @mui
import { Grid, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// section components
import LiveScanWidget from '../../sections/user/scan/LiveScanWidget';

// ----------------------------------------------------------------------

export default function LiveScanPage() {
  const { shopcode, liveId, scancode } = useParams();
  return (
    <>
      <Helmet>
        <title>  Scan Page | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <LiveScanWidget liveId={liveId} scancode={scancode} shopcode={shopcode} />
      </Container>
    </>
  );
}
