import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import { Box, Collapse, List, ListItemText } from '@mui/material';

import Iconify from '../iconify';
//
import { StyledNavItem, StyledNavItemIcon, StyledListSubheader, ActiveRootStyle, ActiveSubStyle, StyledHomeItem } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  isShow: PropTypes.bool,
};

// const latestPost = index === 1 || index === 2;

export default function NavSection({ data = [], isShow = true, ...other }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      {data.map((list) => {
        const { subheader, items, home } = list;
        return (
          <List key={subheader} disablePadding>
            
            <StyledListSubheader>{t(subheader)}</StyledListSubheader>
            {items.map((item) => (
              <NavItem key={item.title} item={item} active={match} isShow={isShow} />
            ))}
            {home && (<HomeItem key={home} item={home} />)}
          </List>
        );
      })}
    </Box>
  );
}
// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  isShow: PropTypes.bool,
};

function NavItem({ item, active, isShow }) {
  const { t } = useTranslation();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  if (children) {
    return (
      <>
        <StyledNavItem
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && ActiveRootStyle),
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

          {isShow && (
            <>
              <ListItemText disableTypography primary={t(title)} />
              {info && info}
              <Iconify
                icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                sx={{ width: 22, height: 22, mr: 2 }}
              />
            </>
          )}
        </StyledNavItem>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = active(path);

                return (
                  <StyledNavItem
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && ActiveSubStyle),
                    }}
                  >
                    <StyledNavItemIcon>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main',
                          }),
                        }}
                      />
                    </StyledNavItemIcon>
                    <ListItemText disableTypography primary={t(title)} />
                  </StyledNavItem>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={t(title)} />

      {info && info}
    </StyledNavItem>
  );
}

HomeItem.propTypes = {
  item: PropTypes.object,
};

function HomeItem({ item }) {
  const { t } = useTranslation();
  const { title, path, icon, info } = item;

  return (
    <StyledHomeItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon sx={{ height: 32, width: 32 }}>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={t(title)} />

      {info && info}
    </StyledHomeItem>
  );
}
