import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Button, Card, CardContent, Grid, Avatar, Typography, Stack } from '@mui/material';

// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';

// utils
import {imgLazy} from '../../../utils/imageLazy';

// path
import PATH_CLIENT from '../../../routes/path/client.path';

// data
import { useFindShops } from '../../../apis/service/web/find';

//
// ----------------------------------------------------------------------
const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingBottom: theme.spacing(8),
}));

// ----------------------------------------------------------------------

ShopCard.propTypes = {
  item: PropTypes.object,
};

function ShopCard({ item }) {
  const { logo, name, notes, code } = item;
 const shopLink = `${PATH_CLIENT.root}/${code}`
  return (
    <Card sx={{ m: 1 }}>
      <Box component="img" alt={name} src={imgLazy(logo)} sx={{ width: '100%', height: 180, objectFit: 'cover' }} />

      <CardContent sx={{ textAlign: 'left' }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant="subtitle" noWrap gutterBottom>
          {name}
        </Typography>
        <Button
                to={shopLink}
                size="small"
                variant="contained"
                component={RouterLink}
              >
                View
              </Button>
              </Stack>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {notes}
        </Typography>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------
ShopListWidget.propTypes = {
  searchQuery: PropTypes.string,
};

export default function ShopListWidget({ searchQuery }) {
  const { data, isLoading, error, refetch } = useFindShops(searchQuery);

  useEffect(() => {
    refetch();
  }, [refetch, searchQuery]);

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  
  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item xs={12}>
        <Typography variant="caption" noWrap gutterBottom>
          {data.length} shop found.
        </Typography>
      </Grid>

      {data.map((shop) => (
        <Grid item xs={12} md={6} lg={3} key={shop.slug}>
          <ShopCard item={shop} />
        </Grid>
      ))}
    </Grid>
  );
}
