// mui
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

// const useStyles = makeStyles((theme) => ({
//   customTextField: {
//     // Define your custom styles here
//     backgroundColor: 'lightblue',
//   },
// }));

const FTextField = (props) => {
  // const classes = useStyles();
  //  className={classes.customTextField}

  return <TextField size="small" {...props} />;
};

export default FTextField;
