import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, Stack, Card, Grid, Typography } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Error from '../../../components/error';
import Loading from '../../../components/loading';

// utils
import { imgLazy } from '../../../utils/imageLazy';

// data
import { useCouponRequests } from '../../../apis/service/user/coupon';

// ----------------------------------------------------------------------

const StyledName = styled(Typography)(({ theme }) => ({
  height: 24,
  width: 180,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  textTransform: 'capitalize',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  padding: theme.spacing(0, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 3),
  },
}));

RequestCard.propTypes = {
  item: PropTypes.object,
};

function RequestCard({ item }) {
  const theme = useTheme();
  const { vchno, reqcode, business, email, name, phone, addedAt} = item;

  return (
    <>
      <Card sx={{ m: 1, px: 5, maxHeight: '280px' }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={'space-between'}
          spacing={{ xs: 0, md: 3 }}
          sx={{ py: 1 }}
        >
          <Stack direction="row" alignItems="center" spacing={1.5} maxWidth={{ md: '110px' }}>
            <Box component={'img'} alt={name} src={imgLazy(business.logo)} />
          </Stack>
          <Stack direction="column" textAlign={'left'}>
            
              <StyledName>{name}</StyledName>
              <StyledName>{email}</StyledName>
              <StyledName>{phone}</StyledName>
              
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" noWrap>
                {'Request Code'}
              </Typography>
              <Typography variant="body1" noWrap>
                {reqcode}
              </Typography>
            </Stack>

            <Typography variant="body1">{addedAt}</Typography>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------

export default function ScratchCardWidget() {
  const theme = useTheme();
  const { data, isLoading, error } = useCouponRequests();

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <>
      <Typography variant="h4" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
      Scratch Cards
      </Typography>

      <Grid container sx={{ pt: theme.spacing(3) }}>
        {data.map((reqcoupon, index) => (
          <Grid key={`rq${index}`} item xs={12} md={6} sx={{ px: 2 }}>
            <RequestCard item={reqcoupon} index={index} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
