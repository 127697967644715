import { Helmet } from 'react-helmet-async';

// @mui
import { Container } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// section widgets
import { AboutWidget } from '../../sections/web/about';
// ----------------------------------------------------------------------

export default function AboutPage() {
  return (
    <>
      <Helmet>
        <title> About - Shop Festia | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <AboutWidget />
      </Container>
    </>
  );
}
