import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Card, Stack, Typography, CardContent } from '@mui/material';

// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';
import Iconify from '../../../components/iconify';

// utils
import { imgLazy } from '../../../utils/imageLazy';


// data
import { useTeams } from '../../../apis/service/client/home';
// ----------------------------------------------------------------------

const StyledAvatar = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  bottom: 0,
  zindex: 9,
  py: 1.5,
  mx: 'auto',
  maxWidth: '100%',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  //  ...bgBlur({ color: theme.palette.background.default }),
 
}));

// ----------------------------------------------------------------------

TeamItem.propTypes = {
  item: PropTypes.object,
};

function TeamItem({ item }) {
  const { name, avatar, role, phone, email, whatsapp } = item;

  return (
    <Card sx={{ p: 3 }}>
      <Stack direction={'column'} spacing={1.8} alignItems={'center'}>
        <StyledAvatar>
          <motion.div whileHover={{ scale: 0.9 }} transition={{ duration: 0.5 }}>
            <Box
              component="img"
              alt={name}
              src={imgLazy(avatar)}
              sx={{ width: '100%', height: 100, objectFit: 'scale-down', borderRadius: '15px' }}
            />
          </motion.div>
        </StyledAvatar>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body2">{role}</Typography>
        <Stack direction={'row'} spacing={1.8} alignItems={'center'}>
          <Typography variant="body2">{phone}</Typography>
        </Stack>
        <Stack direction={'row'} spacing={1.8} alignItems={'center'}>
          <Typography variant="body2">{email}</Typography>
        </Stack>
        <Stack direction={'row'} spacing={1.8} alignItems={'center'}>
          <Typography variant="body2">{whatsapp}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------
TeamWidget.propTypes = {
  shopcode: PropTypes.string,
};
export default function TeamWidget({shopcode}) {
  const theme = useTheme();
  const { data, isLoading, error } = useTeams(shopcode);

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }

  return (
    <Box sx={{ my: theme.spacing(10), py: theme.spacing(4), color: 'common.black' }}>
      <Stack
        direction={'column'}
        alignItems={'center'}
        sx={{ textAlign: 'center', px: 1, textTransform: 'uppercase' }}
        spacing={2}
      >
        <Typography variant="h6">{'Contacts'}</Typography>
      </Stack>

      <CardContent>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' },
          }}
        >
          {data.map((team, index) => (
            <TeamItem key={index} item={team} />
          ))}
        </Box>
      </CardContent>
    </Box>
  );
}
