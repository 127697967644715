import { useQuery } from 'react-query';
import { Api } from '../../api';
import WEB_URLS from '../../urls/web.urls';


export const usePrivacy = () => {
  return useQuery('cms-privacy-policy', async () => {
    const response = await  Api.unget(WEB_URLS.cms.content.privacy);
    return response.data;
  });
};

export const useTerms = () => {
   return useQuery('cms-terms-policy', async () => {
    const response = await  Api.unget(WEB_URLS.cms.content.terms);
    return response.data;
   });
 };

 export const useRefund = () => {
   return useQuery('cms-refund-policy', async () => {
    const response = await  Api.unget(WEB_URLS.cms.content.refund);
    return response.data;
   });
 };

 export const useFraud = () => {
   return useQuery('cms-fraud-policy', async () => {
    const response = await  Api.unget(WEB_URLS.cms.content.fraud);
    return response.data;
   });
 };

 export const useAbout = () => {
  return useQuery('cms-about-us', async () => {
   const response = await  Api.unget(WEB_URLS.cms.content.about);
   return response.data;
  });
};