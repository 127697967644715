// layouts
import DashboardLayout from '../layouts/dashboard';

// guards
import AuthGuard from '../guards/AuthGuard';
// pages

import DashboardPage from '../pages/user/DashboardPage';
import CouponPage from '../pages/user/CouponPage';
import RequestsPage from '../pages/user/RequestsPage';
import HardScanPage from '../pages/user/HardScanPage';
import ReqScanPage from '../pages/user/ReqScanPage';
import LiveScanPage from '../pages/user/LiveScanPage';
import ProfilePage from '../pages/user/ProfilePage';
import ScratchcardPage from '../pages/user/ScratchcardPage';

const UserRoutes = {
  element: <AuthGuard><DashboardLayout /></AuthGuard> ,
  children: [
    { path: 'home', element: <DashboardPage /> },
    { path: 'coupons', element: <CouponPage /> },
    { path: 'profile', element: <ProfilePage /> },
    { path: 'requests', element: <RequestsPage /> },
    { path: 'scratchcards', element: <ScratchcardPage /> },
    { path: 'shopqr/:businesscode', element: <ReqScanPage /> },
    { path: 'scan/:keycode/:couponcode', element: <HardScanPage /> },
    { path: 'livescan/:shopcode/:liveId/:scancode', element: <LiveScanPage /> },
  ],
};

export default UserRoutes;
