import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, Stack, Card, Grid, Typography, CardContent, IconButton } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Error from '../../../components/error';
import Loading from '../../../components/loading';

// data
import { useDrawCompleted } from '../../../apis/service/web/home';

// ----------------------------------------------------------------------

const StyledName = styled(Typography)(({ theme }) => ({
  height: 44,
  width: 180,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  textTransform: 'capitalize',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  padding: theme.spacing(0, 2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 3),
  },
}));

WinnerCard.propTypes = {
  item: PropTypes.object,
};

function WinnerCard({ item }) {
  const { avatar, name, couponcode, prize } = item;

  return (
    <>
      <Card sx={{ m: 1, px: 5, maxHeight: '180px' }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={'space-between'}
          spacing={{ xs: 0, md: 3 }}
          sx={{ py: 1 }}
        >
          <Stack direction="row" alignItems="center" spacing={1.5} maxWidth={{ md: '230px' }}>
            <Avatar alt={name} src={avatar} />
            <StyledName>{name}</StyledName>
          </Stack>

          <Stack direction="column" alignItems={'center'}>
            <Typography variant="caption" noWrap>
              {'Coupon No'}
            </Typography>
            <Typography variant="body" noWrap>
              {couponcode}
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="column" textAlign={'center'}>
            <Typography variant="body2" noWrap>
              {prize.standing}
            </Typography>
            <Typography variant="column" noWrap>
              {prize.prize}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------
WinnerItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
};

function WinnerItem({ item, index }) {
  const [open, setOpen] = useState(index === 0 ? true : false);
  const { title, date, winners } = item;

  return (
    <>
      <Card sx={{ m: 2, width: '100%' }}>
        <CardContent sx={{ textAlign: 'center', backgroundColor: 'red' }}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems={'space-between'}>
              <Typography variant="h6" noWrap sx={{ color: 'common.white' }}>
                {date}
              </Typography>
              <Typography variant="h6" noWrap sx={{ color: 'common.white' }}>
                {title}
              </Typography>
            </Stack>
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{
                mx: 3,
                color: 'common.white',
              }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButton>
          </Stack>
        </CardContent>
        {open && (
          <>
            {winners.map((winner, index) => (
              <WinnerCard key={index} item={winner} />
            ))}
          </>
        )}
      </Card>
    </>
  );
}
// ----------------------------------------------------------------------

export default function CompltetedDraw() {
  const theme = useTheme();
  const { data, isLoading, error } = useDrawCompleted();

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <Grid container sx={{ pt: theme.spacing(3) }}>
      {data.map((draw, index) => (
        <Grid key={`w${index}`} item xs={12} sx={{ px: 2 }}>
          <WinnerItem item={draw} index={index} />
        </Grid>
      ))}
    </Grid>
  );
}
