/* eslint-disable react-hooks/rules-of-hooks */

import Cookies from "js-cookie";

import {baseDomain} from '../config'

// Set cookie with domain attribute

export const AccessKey ='accessToken';
export const RefreshKey ='refreshToken';

const  removeCookie = (cookieName) => {
    localStorage.removeItem(cookieName);
    // Cookies.remove(cookieName);
   // document.cookie = `${cookieName}; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

export function setToken(access){
    localStorage.setItem(AccessKey, access);
    // Cookies.set("accessToken", access,  { path: '/', httpOnly: true, expires: 100 }); // { expires: 7 });
    // const token = Cookies.get(AccessKey);
    //console.log(token);
   //  document.cookie(AccessKey, access, { domain: baseDomain, httpOnly: true });
}

export function setRefreshToken(refresh){
    localStorage.setItem(RefreshKey, refresh);
    // Cookies.set(RefreshKey, refresh,  { domain: baseDomain, httpOnly: true }); // { expires: 7 });

    // document.cookie(RefreshKey, refresh, { domain: baseDomain, httpOnly: true });
 
 }

export function removeToken(){
    removeCookie(AccessKey);
    // document.cookie(AccessKey, "", { domain: baseDomain, httpOnly: true });
 
 }

export function removeRefreshToken(){
    removeCookie(RefreshKey);
    // document.cookie(RefreshKey, "", { domain: baseDomain, httpOnly: true });
 
 }

 export function readAccessToken(){
    // Retrieve the JWT token from the cookie
    const token = localStorage.getItem(AccessKey); // Cookies.get(AccessKey);
    // Check if the token is present and valid
    if (token) {

        return token
    // User is logged in
    // Perform any necessary actions
    } else {
        return ""
    // User is not logged in
    // Redirect to the login page or take appropriate action
    }
}

export function readRefreshToken(access, refresh){
    // Retrieve the JWT token from the cookie
    const token = localStorage.getItem(RefreshKey); // Cookies.get(RefreshKey);
   
    // Check if the token is present and valid
    if (token) {
        return token
    // User is logged in
    // Perform any necessary actions
    } else {
        return ""
    // User is not logged in
    // Redirect to the login page or take appropriate action
    }
}


