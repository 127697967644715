import axios from 'axios';
// config
import {isLive, liveDomain, testDomain, tokenBeaer} from '../config';
import AUTH_URLS from '../apis/urls/auth.urls';

import { setBaseTokens } from './jwt';
import {  AccessKey, RefreshKey } from './token';




function getURL(){
  return `${'https:'}//${isLive? liveDomain:testDomain}`
    // return isLive? `liveURL`:`testURL`
}
// window.location.protocol

const axiosInstance = axios.create({
  baseURL:getURL()
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (typeof error.response === 'undefined') {
      /* alert(
				'A server/network error occurred. ' +
					'Looks like CORS might be the problem. ' +
					'Sorry about this - we will get it fixed shortly.'
			); */

      console.log(
        'A server/network error occurred. ' +
          'Looks like CORS might be the problem. ' +
          'Sorry about this - we will get it fixed shortly.'
      );
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url === AUTH_URLS.refresh) {
      localStorage.removeItem(AccessKey);
      localStorage.removeItem(RefreshKey);
      window.location.href = '/auth/login/';
      return Promise.reject(error);
    }

    if (
      error.response.data.code === 'token_not_valid' &&
      error.response.status === 401 &&
      error.response.statusText === 'Unauthorized'
    ) {
      const refreshToken = localStorage.getItem(RefreshKey);

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post(AUTH_URLS.refresh, { refresh: refreshToken })
            .then((response) => {
              const { access, refresh } = response.data;
              setBaseTokens(access, refresh);
              // use if requied //changes
              axiosInstance.defaults.headers.Authorization = `${tokenBeaer} ${access}`;
              originalRequest.headers.Authorization = `${tokenBeaer} ${access}`;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } 
          console.log('Refresh token is expired', tokenParts.exp, now);
          window.location.href = '/auth/login/';
        
      } else {
        console.log('Refresh token not available.');
        window.location.href = '/auth/login/';
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
