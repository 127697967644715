import { useQuery } from 'react-query';
import {filter} from 'lodash';
import {Api} from '../../api';
import COMMON_URLS from '../../urls/common.urls'; 


export const usePackages = () => {
   return useQuery('web-basepackages', async () => {
      const response = await Api.unget(COMMON_URLS.plan.membership);
      return response.data;
    });
  };

  export const useBoosters = () => {
    return useQuery('web-boosterpackages', async () => {
       const response = await Api.unget(COMMON_URLS.plan.membership);
       return response.data;
     });
   };