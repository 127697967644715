// @mui
import { Grid } from '@mui/material';
// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';
import Markdown from '../../../components/markdown';
// data
import { useFraud } from '../../../apis/service/web/cms';

// ----------------------------------------------------------------------

export default function FraudWidget() {
  const { data, isLoading, error } = useFraud();

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <>
      <Markdown>{data.body}</Markdown>
    </>
  );
}
