import { Navigate, useRoutes } from 'react-router-dom';

// config

// layouts
import SimpleLayout from '../layouts/simple';

// system
import Page404 from '../pages/page404';

// ----------------------------------------------------------------------

// routes
import AuthRoutes from './auth.routes';
import BasicRoutes from './basic.routes';
import UserRoutes from './user.routes';
import ClientRoutes from './client.routes';

export default function Router() {
 
  const baseroutes = useRoutes([    
    { ...BasicRoutes },
    { ...AuthRoutes },
    { ...UserRoutes },
    { ...ClientRoutes },
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);


    return baseroutes;
}


