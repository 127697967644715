import { Helmet } from 'react-helmet-async';
// @mui
import { Stack, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// section widgets
import TermsWidget from '../../sections/web/cms/TermsWidget';

// ----------------------------------------------------------------------

export default function TermsPage() {
  return (
    <>
      <Helmet>
        <title> Terms of Use | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction={'column'} spacing={3} sx={{ py: 10, alignContent: 'center' }}>
          <Typography variant="h3" component={'h1'}  sx={{ display: 'block', textAlign: 'center' }}>
            Terms of Use
          </Typography>
          <TermsWidget />
        </Stack>
      </Container>
    </>
  );
}
