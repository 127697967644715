import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Card, Grid, Typography, CardContent } from '@mui/material';
// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';
import {fDate} from '../../../utils/formatTime';
// data
import { useDrawUpcoming } from '../../../apis/service/web/home';

// ----------------------------------------------------------------------
DrawItem.propTypes = {
  item: PropTypes.object,
};

function DrawItem({ item }) {
  const { title, drawon, drawas } = item;

  return (
    <>
      <Card sx={{ m: 2, width: '100%', height: 220 }}>
        <CardContent sx={{ textAlign: 'center', backgroundColor: 'red', color:'common.white' }}>
          <Typography variant="h6" noWrap gutterBottom>
            Upcomming
          </Typography>
        </CardContent>
        <Stack alignItems={'center'} py={2}>
          <Typography variant="h6" noWrap sx={{ color: 'common.black' }}>
            {fDate(drawon)}
          </Typography>
          <Typography variant="h4" noWrap sx={{ color: 'text.secondary' }}>
            {title}
          </Typography>
        </Stack>
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------

export default function UpcomingDrawWidget() {
  const theme = useTheme();
  const { data, isLoading, error } = useDrawUpcoming();

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <Grid container sx={{ pt: theme.spacing(3) }}>
      {data.map((draw, index) => (
        <Grid key={`d${index}`} item xs={12} md={4} sx={{ px: 2 }}>
          <DrawItem item={draw} />
        </Grid>
      ))}
    </Grid>
  );
}
