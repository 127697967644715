import { Helmet } from 'react-helmet-async';
// @mui
import { Stack, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// section widgets
import FraudWidget from '../../sections/web/cms/FraudWidget';

// ----------------------------------------------------------------------

export default function FraudPage() {
  return (
    <>
      <Helmet>
        <title> Fraud Policy | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction={'column'} spacing={3} sx={{ py: 10, alignContent: 'center' }}>
          <Typography variant="h3" component={'h1'}  sx={{ display: 'block', textAlign: 'center' }}>
            Fraud Policy
          </Typography>
          <FraudWidget />
        </Stack>
      </Container>
    </>
  );
}
