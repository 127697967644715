import { Navigate } from 'react-router-dom';
// layouts
import SimpleLayout from '../layouts/simple';

// pages

import HomePage from '../pages/web/HomePage';
import AboutPage from '../pages/web/AboutPage';
import FindPage from '../pages/web/FindPage';
import FaqPage from '../pages/web/FaqPage';
import JoinPage from '../pages/web/JoinPage';
import WinnerPage from '../pages/web/WinnerPage';
import RegisterPage from '../pages/web/RegisterPage';

// cms
import FraudPage from '../pages/cms/FraudPage';
import TermsPage from '../pages/cms/TermsPage';
import PrivacyPage from '../pages/cms/PrivacyPage';
import RefundPage from '../pages/cms/RefundPage';



const BasicRoutes = {
  element: <SimpleLayout />,
  children: [
    // { path: '', element: <Navigate to="/" />, index: true },
    { path: '', element: <HomePage /> },
    { path: '/about-us', element: <AboutPage /> },
    { path: '/find', element: <FindPage /> },
    { path: '/faqs', element: <FaqPage /> },
    { path: '/join', element: <JoinPage /> },
    { path: '/winners', element: <WinnerPage /> },
    { path: '/shop-register', element: <RegisterPage /> },
    // cms
    { path: '/privacy-policy', element: <PrivacyPage /> },
    { path: '/terms-of-use', element: <TermsPage /> },
    { path: '/refund-policy', element: <RefundPage /> },
    { path: '/fraud-policy', element: <FraudPage /> },
  ],
};

export default BasicRoutes;

//  path="/search/:root?/:category?
