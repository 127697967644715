
import Iconify from '../iconify';


const Icon = (props, icon) => {
    // const classes = useStyles();
    // className={classes.customTextField}
    return <Iconify icon={icon} width={16} height={16} {...props} />
  };
  

export default Icon;