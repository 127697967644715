import { QueryClient, QueryClientProvider } from 'react-query';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
//
import NotistackProvider from './components/notistack-provider';
// hooks
import useAuth from './hooks/useAuth';
import { AuthProvider } from './contexts/AuthContext';
// components
import LoadingScreen from './components/loading-screen';
import ScrollToTop from './components/scroll-to-top';

function App() {
  const queryClient = new QueryClient();
  const { isInitialized } = useAuth();

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <NotistackProvider>
            <ScrollToTop />
            {isInitialized ? <Router /> : <LoadingScreen />}
          </NotistackProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
