import { Helmet } from 'react-helmet-async';
// @mui
import { Stack, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// section widgets
import RefundWidget from '../../sections/web/cms/RefundWidget';

// ----------------------------------------------------------------------

export default function RefundPage() {
  return (
    <>
      <Helmet>
        <title> Refund Policy | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction={'column'} spacing={3} sx={{ py: 10, alignContent: 'center' }}>
          <Typography variant="h3" component={'h1'}  sx={{ display: 'block', textAlign: 'center' }}>
            Refund Policy
          </Typography>
          <RefundWidget />
        </Stack>
      </Container>
    </>
  );
}
