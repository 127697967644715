import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

// @mui
import { Container } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// utils
import { setRefferal } from '../../utils/refferal';
import {loadPromo} from '../../utils/promotion';

// section widgets
import MainBannerWidget from '../../sections/web/home/BannerWidgets';
import AdvtBannerWidgets from '../../sections/web/home/AdvtBannerWidgets';
import UpcommingDrawWidget from '../../sections/web/home/UpcommingDrawWidget';
import CompltetedDrawWidget from '../../sections/web/home/CompltetedDrawWidget';
import FeatureWidget from '../../sections/web/home/FeatureWidget';
import CounterWidget from '../../sections/web/home/CounterWidget';
import PartnerWidget from '../../sections/web/home/PartnerWidget';
import HomeAdvanceWidget from '../../sections/web/home/HomeAdvanceWidget';



// ----------------------------------------------------------------------

export default function HomePage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refcode = queryParams.get('ref');
  setRefferal(refcode);
  loadPromo(queryParams);
  return (
    <>
      <Helmet>
        <title> Home:| {AppName} </title>
      </Helmet>

      <Container maxWidth="xl" sx={{}}>
        <MainBannerWidget />
        <AdvtBannerWidgets />
        <FeatureWidget />
        <UpcommingDrawWidget/>
        <HomeAdvanceWidget />
        <CompltetedDrawWidget />
        <CounterWidget />
        <PartnerWidget />
      </Container>
    </>
  );
}
