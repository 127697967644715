// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography, CardContent } from '@mui/material';
// utils
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------
const featurelist = [
  {
    name: 'Authenticity',
    info: 'Show authenticity by running a genuine, personal competition as oppose to posting content you’re simply being paid to promote.',
    icon: <Iconify icon={'arcticons:2fas-auth'} color="common.white" width={86} />,
  }, // line-md:account
  {
    name: 'Credibility',
    info: 'Gain credibility by using a third-party platform trusted by millions and approved by Facebook, Apple and Google.',
    icon: <Iconify icon={'fluent:scan-thumb-up-20-regular'} color="common.white" width={86} />,
  },
  {
    name: 'Hands-on support',
    info: 'Our team is here to guide you every step of the way to create an online raffle and put our powerful software to work.',
    icon: <Iconify icon={'line-md:question-circle'} color="common.white" width={86} />,
  },
  {
    name: 'Excitement',
    info: 'Get your fans excited about the chance of winning personal prizes and create new ways for them to engage with your brand.',
    icon: <Iconify icon={'mdi:emoticon-excited-outline'} color="common.white" width={86} />,
  },
];
// ----------------------------------------------------------------------

export default function FeatureWidget() {
  const theme = useTheme();
  const titleinfo = 'OTHER THAN BEING free TO USE AND HOSTS RECEIVING 90% OF THE REVENUE FOR EVERY TICKET SOLD';
  const title = 'WHY DO Shops LOVES SHOP FESTIA?';

  return (
    <Box sx={{ mt: theme.spacing(10), mb: theme.spacing(4), py: theme.spacing(4), bgcolor: 'seagreen', color: 'common.white' }}>
      <Stack direction={'column'} alignItems={'center'} sx={{  textAlign:'center', px:1, textTransform:'uppercase' }} spacing={2}>
        <Typography variant="h6">{titleinfo}</Typography>
        <Typography variant="h3">{title}</Typography>
      </Stack>

      <CardContent>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' },
          }}
        >
          {featurelist.map((site) => (
            <Stack direction={'column'} spacing={1.8} key={site.name} alignItems={'center'} sx={{ py: 4.5 }}>
              <Box sx={{ mb: 0.5 }}>{site.icon}</Box>
              <Typography variant="h6">{site.name}</Typography>
              <Typography variant="body2">{site.info}</Typography>
            </Stack>
          ))}
        </Box>
      </CardContent>
    </Box>
  );
}
