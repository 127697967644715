import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

// @mui
import { Grid, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// section components
import RequestScanWidget from '../../sections/user/scan/RequestScanWidget';

// ----------------------------------------------------------------------

export default function ScanPage() {
  const { businesscode } = useParams();

  return (
    <>
      <Helmet>
        <title>  Scan Page | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <RequestScanWidget businesscode={businesscode}/>
      </Container>
    </>
  );
}
