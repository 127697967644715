import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import { Box, Typography, DialogActions, Stack } from '@mui/material';
import { Icon, Icons } from '../icons';

// import { useSnackbar } from 'notistack';
// const { enqueueSnackbar } = useSnackbar();
// enqueueSnackbar(t('word.updatedSuccessfully'), { variant: 'error' });
// --------------------------------------
const StyledRoot = styled(DialogActions)(({ theme }) => ({
  color: theme.palette.text.primary,
  position: 'fixed',
  bottom: 0,
  minWidth: 'lg',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  marginInline: theme.spacing(2),
}));

// --------------------------------------

StatusItem.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
};
function StatusItem({ title, variant }) {
  let bgcolor = '#fff';
  let color = '#000';
  let icon = Icons.success;
  let flip = false;

  switch (variant) {
    case 'success':
      bgcolor = 'green';
      color = '#fff';
      icon = Icons.success;
      flip = false;
      break;
    case 'error':
      bgcolor = 'red';
      color = '#fff';
      break;
    case 'warning':
      bgcolor = 'orange';
      color = '#fff';
      break;
    default:
      bgcolor = '#fff';
      color = '#000';
  }

  return (
    <Stack direction={'row'} sx={{ backgroundColor: bgcolor, color: color }}>
      <Box sx={{ px: 3 }}>
        <Icon icon={icon} hFlip={flip} color={color} width={24} height={24} />
      </Box>
      <Typography variant="h1" sx={{ color: color, display: 'block', textAlign: 'center' }}>
        {title}
      </Typography>
    </Stack>
  );
}

NotifyActions.propTypes = {
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  status: PropTypes.number,
};

export default function NotifyActions({  data, status, statusText, isLoading = false }) {
  const { t } = useTranslation();

  if (isLoading) {
    return <></>;
  }
  const error =  status !== 200 & status !== 201 & status !== 202

  return (
    <StyledRoot>
      {error && <StatusItem title={statusText} variant={'error'} />}
      {status === '201' && <StatusItem title={t(`word.${statusText}`)} variant={'success'} />}
      {status === '202' && <StatusItem title={t(`word.${statusText}`)} variant={'success'} />}
      {status === '200' && <StatusItem title={t(`word.${statusText}`)} variant={'success'} />}
    </StyledRoot>
  );
}
