// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, Stack } from '@mui/material';
// utils
//
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AboutWidget() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ py: 10, color: '#000', display: 'block', textAlign: 'center' }}>
        Why Shop Festia ?
        </Typography>

        <Typography variant="body1" sx={{ color: '#000', display: 'block', textAlign: 'center', pb:10 }}>
        Shop festia is an annual event that offers a unique shopping experience to customers from all over the world. Shop festia is not only about amazing discounts and deals on a wide range of products, but also about entertainment, culture, and fun. From 14 January 2024 to 14 January 2025, you can enjoy spectacular fireworks, live shows, concerts, fashion shows, and more at various venues across the city. Shop festia is also a great opportunity to win exciting prizes, such as cars, gold, and cash, through raffles and draws. Don’t miss this chance to explore the best of India and make your shopping dreams come true at Shop festia.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Card sx={{ m: 4, p:4, borderRadius: 5, boxShadow: 'none' }}>
          <Stack>
            <Typography variant="h5" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
              Vision
            </Typography>
            <Typography variant="body1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
              ASWe offer a range of study materials, practice tests, and personalized coaching to ensure that our
              students are fully prepared for their exams. With our comprehensive approach to exam preparation, we have
              helped countless students succeed in their professional endeavors.
            </Typography>
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Card sx={{ m: 4, p:4, borderRadius: 5, boxShadow: 'none' }}>
          <Stack>
            <Typography variant="h5" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
              Mission
            </Typography>
            <Typography variant="body1" sx={{ color: '#000', display: 'block', textAlign: 'center' }}>
              ASWe offer a range of study materials, practice tests, and personalized coaching to ensure that our
              students are fully prepared for their exams. With our comprehensive approach to exam preparation, we have
              helped countless students succeed in their professional endeavors.
            </Typography>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
}
