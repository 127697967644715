import { useQuery } from 'react-query';
import { filter } from 'lodash';
import { Api } from '../../api';
import WEB_URLS from '../../urls/web.urls';

export const useHardRead = (keycode, couponcode) => {
  const formData = new FormData();

  formData.append('key', keycode);
  return useQuery('user-hard-read', async () => {
    const response = await Api.post(`${WEB_URLS.user.scan.hard}${couponcode}/`, formData);
    return response.data;
  });
};

function formHardValues(reqid, keycode, values) {
  const { name, email, phone, vchno } = values;
  const formData = new FormData();

  formData.append('reqid', reqid);
  formData.append('key', keycode);
  formData.append('name', name);
  formData.append('email', email);
  formData.append('phone', phone);
  formData.append('vchno', vchno);
  return formData;
}

export const AddHardScan = async (reqid, keycode, couponcode, values) => {
  const fromdata = formHardValues(reqid, keycode, values);
  const response = await Api.post(`${WEB_URLS.user.scan.hard}${couponcode}/`, fromdata);
  return response.data;
};

function formValues(businesscode, values) {
  const { name, email, phone, vchno } = values;
  const formData = new FormData();

  formData.append('bcode', businesscode);
  formData.append('name', name);
  formData.append('email', email);
  formData.append('phone', phone);
  formData.append('vchno', vchno);
  return formData;
}

export const AddShopScan = async (businesscode, values) => {
  const fromdata = formValues(values);
  const response = await Api.post(`${WEB_URLS.user.scan.req}`, fromdata);
  return response.data;
};

export const useVerifyScan = () => {
  return useQuery('user-scan-verify', async () => {
    const response = await Api.get(WEB_URLS.user.scan.verify);
    return response.data;
  });
};


export const useLiveRead = (liveid, scancode, shopcode) => {
  const formData = new FormData();

  formData.append('scancode', scancode);
  formData.append('shopcode', shopcode);
  return useQuery('user-live-read', async () => {
    const response = await Api.post(`${WEB_URLS.user.scan.live}${liveid}/`, formData);
    return response.data;
  });
};


function formLiveValues(scancode, shopcode, values) {
  const { name, email, phone, vchno } = values;
  const formData = new FormData();
  formData.append('scancode', scancode);
  formData.append('shopcode', shopcode);
  formData.append('name', name);
  formData.append('email', email);
  formData.append('phone', phone);
  formData.append('vchno', vchno);
  return formData;
}

export const AddLiveScan = async (liveId, scancode, values) => {
  const fromdata = formLiveValues(scancode, scancode, values);
  const response = await Api.post(`${WEB_URLS.user.scan.live}${liveId}/`, fromdata);
  return response.data;
};