
export const AppName = "SHOPFESTIA";
export const AppSlogon = "Health Care Consultant";

export const version ="beta-01";
export const build = "18-oct-2023";

export const tokenBeaer="JWT"

export const mapConfig = "pk.eyJ1IjoiZGV2bWluaW1hbCIsImEiOiJja2s1YTV5bXkxc3luMnZwY3ZkNHRyZ2E4In0.wsgLsAl-3Sd1PaLy-lA6vA"; 
// export const mapConfig =  process.env.REACT_APP_MAP_MAPBOX;

export const isLive = true;
export const isS3 = false;
export const liveDomain = 'api.shopfestia.com';
export const testDomain = 'localhost:8000';
export const baseDomain = 'localhost:3000';

export const MainDomains = ['www', 'ag', 'ad', 'api', 'live', 'test'];

