import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Link, Box, Stack, Grid, Typography, Tooltip, Divider } from '@mui/material';
// components

import Logo from '../../../components/logo';

import navConfig from './config';

// data

//
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const StyledTitle = styled(Link)(({ theme }) => ({
  overflow: 'hidden',
  WebkitLineClamp: 2,
  textTransform: 'capitalize',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  padding: theme.spacing(0, 0.2),
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    color: 'gray',
  },
}));

const StyledSocial = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.1, 0.7),
  color: 'white',
  '&:hover': {
    color: 'gray',
  },
}));

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const SOCIAL = [
  {
    label: 'Facebook',
    icon: 'facebook.svg',
    path: '/',
  },
  {
    label: 'Instagram',
    icon: 'instagram.svg',
    path: '/',
  },
  {
    label: 'Youtube',
    icon: 'youtube.svg',
    path: '/',
  },
];

function SocialLinks() {
  const theme = useTheme();

  return (
    <Stack direction={'column'} alignItems={'center'} sx={{ py: theme.spacing(6) }}>
      <Typography variant="body2" noWrap>
        Social Links
      </Typography>
      <Stack direction={'row'} alignContent={'center'} sx={{ py: theme.spacing(1) }}>
        {SOCIAL.map((social, index) => (
          <Tooltip key ={index} title={social.label}>
            <StyledSocial component={RouterLink} to={social.path}>
              <Box
                component="img"
                alt={social.label}
                src={`/asset/icons/social/${social.icon}`}
                sx={{ width: 24, height: 24, color: 'common.white' }}
              />
            </StyledSocial>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
}

function BaseLinks() {
  const theme = useTheme();

  return (
    <Stack direction={'column'} alignItems={'center'} sx={{ py: theme.spacing(6) }}>
      <Typography variant="body2" noWrap>
        8943 123 144
      </Typography>
      <Typography variant="body2" noWrap>
        info@shopfestia.com
      </Typography>
    </Stack>
  );
}
// ----------------------------------------------------------------------

export default function Footer() {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={theme.spacing(3)} sx={{ mt:3, backgroundColor: 'common.black', color: 'common.white' }}>
        <Grid item xs={12} md={6} lg={3}>
          <Stack direction={'column'} alignItems={'center'}>
            <Logo sx={{ my: theme.spacing(3), width: 'auto', height: 120 }} />
            <Box sx={{ mb: 3 }} />
            <Typography variant="caption" noWrap>
              Powered By
            </Typography>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Box
                component="img"
                alt={'GarlikLogo'}
                src={'/asset/logo/garliksofts.png'}
                sx={{ height: 32, objectFit: 'cover' }}
              />
              <Typography variant="h6" noWrap>
                Garliksofts
              </Typography>
            </Stack>
            <Divider color="common.gray" sx={{ display: { lg: 'none' } }} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Stack
            direction="column"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            <Typography variant="caption" noWrap>
              Quick Links
            </Typography>
            {navConfig.map((menu, index) => (
              <StyledTitle
                key={index}
                color="inherit"
                variant="body2"
                underline="hover"
                component={RouterLink}
                to={menu.path}
                sx={{
                  color: 'common.white',
                }}
              >
                {menu.title}
              </StyledTitle>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SocialLinks />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <BaseLinks />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="column"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            <Typography variant="caption" noWrap textAlign={'center'}>
              {new Date().getFullYear()} &copy; Garliksofts all right reserved
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
