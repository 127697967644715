import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, RadioGroup, Stack, Card, Grid, Typography} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
 
// ----------------------------------------------------------------------

const StyledName = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  fontSize: theme.spacing(3.5),
  fontWeight: 400,
  WebkitLineClamp: 2,
  textTransform: 'capitalize',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  padding: theme.spacing(0, 2),
}));

// ----------------------------------------------------------------------

PackageItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  onHandle: PropTypes.func,
  selectedItem: PropTypes.string,
};

function PackageItem({ item, index, onHandle, selectedItem }) {
  const theme = useTheme();
  const { id, name, notes, amount, rate, offer } = item;
  const onHandleClick = () => {
    onHandle(id);
  };
  const isSelected = id===selectedItem;

  return (
    <>
      <motion.div
        whileHover={{ scale: 1.01 }}
        transition={{ duration: 0.3 }}
        sx={{
          width: '100%',
        }}
      >
        <Card
          onClick={onHandleClick}
          sx={{
            p: 1,
            ...(isSelected && { border: `solid 3px ${theme.palette.primary.main}` }),
            '&:hover': {
              boxShadow: theme.shadows[13],
            },
          }}
        >
          <Stack direction={'column'} textAlign={'center'} spacing={1.5}>
            <Stack direction={'row'} alignContent={'center'} justifyContent={'center'} spacing={1}>
              <Label
                variant="filled"
                color={'error'}
                sx={{ opacity: 0.55, borderRadius: '15px' }}
              >
                SAVE {Math.floor(offer)}%
              </Label>
            </Stack>
            <Stack direction={'row'} alignContent={'center'} justifyContent={'space-between'} spacing={2}>
            <Iconify icon={isSelected?"fa:circle-o":"mdi-light:circle"} color={isSelected?theme.palette.primary.main:theme.palette.grey}  sx={{pt:0.75, height:'40px', width:'40px'}}/>
              <StyledName>{name}</StyledName>
              <Box sx={{ flexGrow: 1 }} />
            </Stack>

            <Stack direction={'column'} alignContent={'center'} justifyContent={'center'} spacing={1}>
              <Typography variant="body" noWrap sx={{ textDecoration: 'line-through', color: 'gray' }}>
                ₹{rate}
              </Typography>
              <Typography variant="h3" noWrap>
                ₹{Math.floor(amount)}.00
              </Typography>
              <Typography variant="caption" sx={{ minHeight: '44px' }}>
                {notes}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </motion.div>
    </>
  );
}
// ----------------------------------------------------------------------
PackageWidget.propTypes = {
  selectedItem: PropTypes.string,
  onHandle: PropTypes.func,
  packages:PropTypes.array
};

export default function PackageWidget({ packages, selectedItem, onHandle }) {
  const theme = useTheme();


  return (
    <Stack direction={'column'} sx={{ pb: 10 }}>
      <Typography
        variant="h3"
        noWrap
        sx={{ color: 'common.black', py: 5, textAlign: 'center', textTransform: 'capitalize' }}
      >
        Select your membership plan
      </Typography>
      <RadioGroup row value={selectedItem}>
        <Grid container spacing={theme.spacing(3)}>
          {packages.map((pack, index) => (
            <Grid item key={`p${index}`} xs={12} md={6} lg={3}>
              <PackageItem item={pack} index={index} onHandle={onHandle} selectedItem={selectedItem} />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Stack>
  );
}
