import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Box,
  OutlinedInput, FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { MIconButton } from '../../../components/@mui-extend';
import Iconify from '../../../components/iconify';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// apis
import {Api} from '../../../apis/api';
import AUTH_URLS from '../../../apis/urls/auth.urls'; 

// ----------------------------------------------------------------------

function maxLength(object) {
  if (object.target.value.length > object.target.maxLength) {
    return (object.target.value = object.target.value.slice(
      0,
      object.target.maxLength
    ));
  }
}
// ----------------------------------------------------------------------

async function RESENDSMSOTP (vid){
    // const body=JSON.stringify({country,phone});
    const formData = new FormData();
    formData.append('vid', vid);
    const response = await Api.unpost(`${AUTH_URLS.otp.resend}${vid}/`);
    return response;
   };

   async function VERIFYOTP (vid,otp){
    // const body=JSON.stringify({country,phone});
    const formData = new FormData();
    formData.append('vid', vid);
    formData.append('otp', otp);
    const response = await Api.unpost(AUTH_URLS.otp.verify, formData);
    return response;
   };


// ----------------------------------------------------------------------
OTPScreen.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  vid: PropTypes.string,
  setVid: PropTypes.func,
  setHashcode: PropTypes.func,
};
export default function OTPScreen({step, setStep, vid, setVid, setHashcode}) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [seconds, setSeconds] = useState(180);


  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }    
  },[seconds]);
  

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.number().required('Code is required'),
    code2: Yup.number().required('Code is required'),
    code3: Yup.number().required('Code is required'),
    code4: Yup.number().required('Code is required'),
    // code5: Yup.number().required('Code is required'),
    // code6: Yup.number().required('Code is required')
  });

  const formik = useFormik({
    initialValues: {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      // code5: '',
      // code6: ''
    },
    validationSchema: VerifyCodeSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        HandleVerifyOtp()
       
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code || error.message });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, values, isValid, touched, handleSubmit, isSubmitting, getFieldProps , setFieldValue} = formik;
  
  async function  HandleVerifyOtp()
  {
    let otp = ''
    Object.values(values).map((item) => (otp += item ))
    
     
    const response = await VERIFYOTP(vid, otp);
      if (response.status===208)
      {
          enqueueSnackbar('OTP is not valied', {
          variant: 'warning',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Iconify icon={'eva:close-fill'} />
            </MIconButton>
          )
        });
        setFieldValue('otp','');
      }else{
        const {hashcode } =response.data;
        setHashcode(hashcode);
        setStep(3);
      }
     
    }

  async function  HandleOtp ()
  {
    
    const response = await RESENDSMSOTP(vid);
      if (response.status===226)
      {
        enqueueSnackbar('This Number already registered, Please Login', {
          variant: 'info',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Iconify icon={'eva:close-fill'} />
            </MIconButton>
          )
        });
      }else{
        const {vid} =response.data;
        setVid(vid);
        setStep(2);
        setSeconds(180);
      }
     
    }

    function secondsToHms(d) {
      d = Number(d);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);
  
      var mDisplay = m > 0 ? (m >= 9 ? "" : "0") + m : "00";
      var sDisplay = s > 0 ? (s >= 9 ? "" : "0") + s : "00";
      return  mDisplay  +":"+ sDisplay; 
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {Object.keys(values).map((item) => (
            <Box key={item} sx={{ mx: 1 }}>
              <OutlinedInput
                {...getFieldProps(item)}
                type="number"
                placeholder="-"
                onInput={maxLength}
                error={Boolean(touched[item] && errors[item])}
                inputProps={{
                  maxLength: 1,
                  sx: {
                    padding: 0,
                    textAlign: 'center',
                    width: { xs: 36, sm: 56 },
                    height: { xs: 36, sm: 56 }
                  }
                }}
              />
            </Box>
          ))}
        </Box>

        <FormHelperText error={!isValid} style={{ textAlign: 'right' }}>
          {!isValid && 'Code is required'}
        </FormHelperText>

        <Box sx={{ mt: 3 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            pending={isSubmitting}
          >
            Verify
          </LoadingButton>
        </Box>
        <LoadingButton
          fullWidth
          disabled = { step === 2 ?   (seconds >0 ? true :false): false}
          size="large"
          type="button"
          variant="contained"
          onClick={HandleOtp}
          sx={{mt:3}}
        >
          { step === 2 ? (seconds >0 ? `Resend in ${secondsToHms(seconds)}`:'Resend OTP'): 'Get OTP'}
        </LoadingButton>
      </Form>
    </FormikProvider>
   
  );
}
