// layouts
import SimpleLayout from '../layouts/simple';

// pages

// import HomePage from '../pages/client/HomePage';
import ShopPage from '../pages/client/ShopPage';
import AboutPage from '../pages/client/AboutPage';
import ProductPage from '../pages/client/ProductPage';

const ClientRoutes = {
  path: '/s',
  element: <SimpleLayout />,
  children: [
    { path: ':shopcode', element: <ShopPage /> },
    { path: 'aboutus/:shopcode', element: <AboutPage /> },
    { path: 'showcase/:shopcode', element: <ProductPage /> },
    
  ],
};

export default ClientRoutes;