import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MIconButton } from '../../../components/@mui-extend';
// components
import Iconify from '../../../components/iconify';
//
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------
function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { login, isAuthenticated, status } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email  is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        let username = values.email;
        if (isNumeric(username)) {
          if (username.length === 10) {
            username = '91-' + username;
          }
        }

        await login(username, values.password);

        if (isAuthenticated) {
          enqueueSnackbar('Login success', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </MIconButton>
            ),
          });
        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        // enqueueSnackbar('User name or password is incorrect.', {
        //   variant: 'error',
        //   action: (key) => (
        //     <MIconButton size="small">
        //       <Iconify icon={'eva:close-fill'} />
        //     </MIconButton>
        //   )
        //   });
        // console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.code || error.message });
        }
      }
      if (status === 403) {
        // enqueueSnackbar('You have no permission to access', {
        //   variant: 'error',
        //   action: (key) => (
        //    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        //      <Iconify icon={'eva:close-fill'} />
        //    </MIconButton>
        //  )
        //  });
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            autoComplete="email"
            type="text"
            label="Phone/Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            sx={{ mb: 3 }}
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Checkbox name="remember" label="Remember me" />
          <Link variant="subtitle2" underline="hover" component={RouterLink} to={'/auth/reset-password'} >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>

        <Button fullWidth size="large" type="button" variant="outlined" component={RouterLink} to={'/auth/register'} sx={{mt:4}}>
          New User
        </Button>
      </Form>
    </FormikProvider>
  );
}
