import { useQuery } from 'react-query';
import {Api} from '../../api';
import WEB_URLS from '../../urls/web.urls'; 

export const useMainBanner = () => {
   return useQuery('home-mainbanner', async () => {
      // return CAROUSELS;
      const response = await Api.unget(`${WEB_URLS.pro.banner.main}`);
      return response.data;
   });
  };

  export const useAdvtBanners = () => {
   return useQuery('home-advtbanners', async () => {
      // return ADVTBANNERS;
      const response = await  Api.unget(WEB_URLS.pro.banner.advt1);
      return response.data;
   });
  };

  export const useClients = () => {
   return useQuery('home-mainclients', async () => {
      // return CLIENTS;
      const response = await Api.unget(WEB_URLS.client.clients);
      return response.data;
   });
  };

  export const useDrawUpcoming = () => {
   return useQuery('home-draw-upcoming', async () => {
      // return WINNERS;
      const response = await Api.unget(WEB_URLS.coupon.draw.upcomming);
      return response.data;
   });
  };

  export const useDrawCompleted = () => {
   return useQuery('home-draw-completed', async () => {
      // return WINNERS;
      const response = await Api.unget(WEB_URLS.coupon.draw.completed);
      return response.data;
   });
  };

  export const useCounter = () => {
   return useQuery('home-counter', async () => {
      // return COUNTER;
      const response = await Api.unget(WEB_URLS.cms.counter);
      return response.data;
   });
  };

  export const usePartners = () => {
   return useQuery('home-partners', async () => {
      // return PARTNERS;
      const response = await Api.unget(WEB_URLS.pro.partners);
      return response.data;
   });
  };

export const usePosts = () => {
 return useQuery('posts', async () => {
    const response =  await fetch('https://jsonplaceholder.typicode.com/posts');
    return response.data;
 });
};