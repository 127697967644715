import { Helmet } from 'react-helmet-async';

// @mui
import { Container } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// section widgets
import FaqForm from '../../sections/web/faqs/faqform';

// ----------------------------------------------------------------------
export default function FaqPage() {
  return (
    <>
      <Helmet>
        <title> Faqs | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <FaqForm />
      </Container>
    </>
  );
}
