// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_USER = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, '/home'),
  coupons: path(ROOTS_DASHBOARD, '/coupons'),
  requests: path(ROOTS_DASHBOARD, '/requests'),
  scratchcard: path(ROOTS_DASHBOARD, '/scratchcards'),
  profile: path(ROOTS_DASHBOARD, '/profile'),
};

export default PATH_USER;
