// mui
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

// const useStyles = makeStyles((theme) => ({
//   customTextField: {
//     // Define your custom styles here
//     backgroundColor: 'lightblue',
//   },
// }));

const AmountField = (props) => {
  // const classes = useStyles();
  // className={classes.customTextField}

  return <TextField type="number" size="small" {...props} />;
};

export default AmountField;
