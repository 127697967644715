import { useQuery } from 'react-query';
import {filter} from 'lodash';
import {Api} from '../../api';
import WEB_URLS from '../../urls/web.urls';

// hooks
import useLocales from '../../../hooks/useLocales';

export const useFaqs = (searchtext) => {
   const { currentLang } = useLocales();
   return useQuery('web-faqs', async () => {
      // return filter(
      //    FAQS,
      //    (_item) =>
      //      _item.question.toLowerCase().includes(searchtext)
      //  );
      const response = await  Api.unget(`${WEB_URLS.cms.faq.all}?lang=${currentLang.value}`);
      return response.data;
   });
  };