import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, DialogActions } from '@mui/material';
// hooks
// components
import Logo from '../../components/logo';

// APP Name
import { AppName } from '../../config';
// sections
import { RegSmsForm, RegSmsFinalForm } from '../../sections/auth/register';
import { OtpScreen } from '../../sections/auth/otp';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '90vh',
}));

const StyledHead = styled(DialogActions)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 380,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(6, 0),

  [theme.breakpoints.up('md')]: {
    // boxShadow: theme.customShadows.card,
    // backgroundColor: theme.palette.background.default,
  },
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [vid, setVid] = useState('');
  const [hashcode, setHashcode] = useState('');
  const [username, setUsername] = useState('');

  return (
    <>
      <Helmet>
        <title>
          {t('auth.register')} | {AppName}
        </title>
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Logo disabledLink sx={{ width: 'auto', height: 120 }} />

            <Typography variant="h4" gutterBottom>
              {t('auth.w-register.welcome')}
            </Typography>

            <Divider sx={{ my: 3 }}>
              <StyledHead>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('auth.register')}
                </Typography>
              </StyledHead>
            </Divider>
            {step === 1 && <RegSmsForm setStep={setStep} setVid={setVid} setUsername={setUsername} />}
            {step === 2 && (
              <OtpScreen step={step} setStep={setStep} vid={vid} setVid={setVid} setHashcode={setHashcode} />
            )}
            {step === 3 && <RegSmsFinalForm username={username} vid={vid} hashcode={hashcode} />}

            <Link variant="subtitle2" underline="hover" component={RouterLink} to={'/auth/login'} sx={{ mt: 4 }}>
              Already an user? Please Login
            </Link>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
