import { Navigate } from 'react-router-dom';
// layouts
import SimpleLayout from '../layouts/simple';

// guards
import GuestGuard from '../guards/GuestGuard';

// auth
import LoginPage from '../pages/auth/LoginPage';
import RegisterPage from '../pages/auth/RegisterPage';
import ResetPage from '../pages/auth/ResetPage';

const authRoutes={
    path: '/auth',
    element:<GuestGuard><SimpleLayout /></GuestGuard>,
    children: [
      { element: <Navigate to="/auth/login" />, index: true },
      { path: 'login', element: <LoginPage /> },
      { path: 'register', element: <RegisterPage /> },
      { path: 'reset-password', element: <ResetPage /> },
      { path: '*', element: <Navigate to="/404" replace />},
    ],
  };

export default authRoutes;