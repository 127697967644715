import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Button, Box, Tooltip, IconButton, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon, Icons } from '../icons';

// --------------------------------------
const StyledRoot = styled(DialogActions)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  marginInline: theme.spacing(2),
}));

// --------------------------------------

MenuButton.propTypes = {
  item: PropTypes.object,
};
function MenuButton({ item }) {
  const { title, icon, flip, color, func, enable } = item;
  if (!enable) {
    return <></>;
  }
  return (
    <Tooltip title={title}>
      <IconButton onClick={func} sx={{ mr: 3 }}>
        <Icon icon={icon} hFlip={flip} color={color} width={24} height={24} />
      </IconButton>
    </Tooltip>
  );
}

FormActions.propTypes = {
  top: PropTypes.bool,
  loading: PropTypes.bool,
  isCreating: PropTypes.bool,
  onDelete: PropTypes.func,
  onReset: PropTypes.func,
  onPrint: PropTypes.func,
  permssions: PropTypes.object,
};

export default function FormActions({
  top = false,
  loading = false,
  isCreating = true,
  onDelete,
  onReset,
  onPrint,
  permssions,
}) {
  const { t } = useTranslation();

  const { canCreate, canUpdate, canPrint, canDelete } = permssions;
  const isSave = (canCreate && isCreating) || (canUpdate && !isCreating);

  const isPrint = isSave && canPrint && onPrint;

  const isReset = !!onReset;
  const isDelete = !isCreating && canDelete;

  const ITEMS = [
    {
      title: t('func.reset'),
      icon: Icons.reset,
      flip: true,
      color: 'inherit',
      busy: false,
      func: onReset,
      enable: isReset,
    },
    {
      title: t('func.delete'),
      icon: Icons.trash,
      flip: false,
      color: 'inherit',
      busy: false,
      func: onDelete,
      enable: isDelete,
    },
  ];

  return (
    <StyledRoot>
      {ITEMS.map((item, index) => (
        <MenuButton key={index} item={item} />
      ))}
      {!top && <Box sx={{ flexGrow: 1 }} />}
      {isPrint && (
        <Button type="submit" variant="outlined" color="inherit" onClick={onPrint} sx={{ mr: 1.2 }}>
          {t('func.print')} <Icon icon={Icons.print} width={18} height={18} sx={{ ml: 0.5 }} />
        </Button>
      )}
      {isSave && (
        <LoadingButton type="submit" variant="contained" loading={loading} loadingIndicator="Loading...">
          {t(isCreating ? 'func.add' : 'func.save')}
          <Icon icon={Icons.save} width={18} height={18} sx={{ ml: 0.5 }} />
        </LoadingButton>
      )}
    </StyledRoot>
  );
}
