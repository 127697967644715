import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { motion } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, CardContent, Grid, Typography, Stack } from '@mui/material';
import Label from '../../../components/label';
// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';

// utils
import { imgLazy } from '../../../utils/imageLazy';

// data
import { useProducts } from '../../../apis/service/client/product';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

ProductCard.propTypes = {
  item: PropTypes.object,
};

function ProductCard({ item }) {
  const { img, name, notes, price, rate, offer } = item;
  const showPrice = rate !== 0;
  return (
    <Card sx={{ m: 1 }}>
      <motion.div whileHover={{ scale: 1.2 }} transition={{ duration: 0.3 }}>
        <Box component="img" alt={name} src={imgLazy(img)} sx={{ width: '100%', height: 180, objectFit: 'cover' }} />
      </motion.div>

      <CardContent sx={{ textAlign: 'left' }}>
        <Stack>
          <Typography variant="subtitle" noWrap gutterBottom>
            {name}
          </Typography>
          <Label variant="filled" color={'info'} sx={{ opacity: 0.55, borderRadius: '15px' }}>
            SAVE {Math.floor(offer)}%
          </Label>
        </Stack>

        <Typography variant="body2" sx={{ color: 'text.secondary', minHeight: 88 }}>
          {notes}
        </Typography>
        {showPrice && (
          <>
            <Typography variant="body2" sx={{ color: 'text.secondary', textDecoration: 'line-through' }}>
              ₹{rate}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              ₹{price}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------
ProductWidget.propTypes = {
  shopcode: PropTypes.string,
  searchQuery: PropTypes.string,
};

export default function ProductWidget({ shopcode, searchQuery }) {
  const { data, isLoading, error, refetch } = useProducts(shopcode, searchQuery);

  useEffect(() => {
    refetch();
  }, [refetch, searchQuery]);

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item xs={12}>
        <Typography variant="caption" noWrap gutterBottom>
          {data.length} products found.
        </Typography>
      </Grid>

      {data.map((shop) => (
        <Grid item xs={12} sm={6} md={4}  key={shop.id}>
          <ProductCard item={shop} />
        </Grid>
      ))}
    </Grid>
  );
}
