// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Why Shop Festia?',
    path: '/about-us',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'data flow',
  //   path: '/dataflow',
  //   icon: icon('ic_cart'),
  // },
  {
    title: 'join us',
    path: '/join',
    icon: icon('ic_blog'),
  },
  {
    title: 'find shop',
    path: '/find',
    icon: icon('ic_blog'),
  },
  {
    title: 'winners gallery',
    path: '/winners',
    icon: icon('ic_user'),
  },
  {
    title: 'faqs',
    path: '/faqs',
    icon: icon('ic_user'),
  },
  {
    title: 'privacy & policy',
    path: '/privacy-policy',
    icon: icon('ic_user'),
  },
  {
    title: 'terms of use',
    path: '/terms-of-use',
    icon: icon('ic_user'),
  },
  {
    title: 'fraud policy',
    path: '/fraud-policy',
    icon: icon('ic_user'),
  }
];

export default navConfig;
