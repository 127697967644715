import { Helmet } from 'react-helmet-async';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Container, Grid, Link } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// components

// section widgets
import JoinUsWidget from '../../sections/web/joinus/JoinUsWidget';
import BoostWidget from '../../sections/web/joinus/BoostWidget';
import PackageWidget from '../../sections/web/package/PackageWidget';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function JoinPage() {
  return (
    <>
      <Helmet>
        <title> Join | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <JoinUsWidget />
        <PackageWidget />
        <BoostWidget />
      </Container>
    </>
  );
}
