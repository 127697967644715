import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { useTheme, alpha, styled } from '@mui/material/styles';
import { Box, Button, Grid, Typography, Stack } from '@mui/material';

// components

// ----------------------------------------------------------------------
const Benfits = [
  'Attract more customers! Sell printed tickets and raffle tickets together with online offerings, and track it all from your dashboard.',
  'Verify coupons based on the sales',
  'Safeguard your personal information with robust privacy protection measure',
  'Defend your website against unwanted access with IP and country blocking, included with Hostinger C',
];
// ----------------------------------------------------------------------

export default function BoosterWidget() {
  const theme = useTheme();
  const imagePath = `/asset/icons/others/tick.svg`;
  return (
    <>
      <Stack sx={{ py: 5 }} alignItems="center">
        <Typography variant="h3" sx={{ display: 'block', textAlign: 'center', textTransform: 'capitalize' }}>
          Premium service in india
        </Typography>
        <Box mb={2} sx={{ pt: theme.spacing(3) }}>
          <Button
            to={'/shop-register'}
            size="small"
            variant="contained"
            component={RouterLink}
            sx={{ py: theme.spacing(1.5), px: theme.spacing(4) }}
          >
            Get Started
          </Button>
        </Box>
      </Stack>

      <Grid container>
        <Grid item xs={12} md={6} py={theme.spacing(14)}>
          <Typography variant="h4" sx={{ py: 6, display: 'block', textAlign: 'center', textTransform: 'capitalize' }}>
            We makes Your sales High.
          </Typography>
          {Benfits.map((benfit, index) => (
            <Stack key={index} direction={'row'}  spacing={2} sx={{ p: 2 }}>
             
             <Box component="img" alt={'Shop festia'} src={imagePath} alignSelf={'left'}  sx={{ width: 50, height: 50, objectFit: 'scale-down' }} />
              
              <Typography variant="body1" sx={{ display: 'block' }}>
                {benfit}
              </Typography>
            </Stack>
          ))}
        </Grid>
        <Grid item xs={12} md={6} sx={{py:{md:theme.spacing(5), lg:theme.spacing(10)}, mt:{md:1, lg:theme.spacing(20)}}}>
          <Box
            component={'img'}
            src={'/asset/images/web/img_1.jpg'}
            sx={{ height: 'auto', objectFit: 'cover', borderRadius: 5, boxShadow: 'none' }}
          />
        </Grid>
      </Grid>
    </>
  );
}
