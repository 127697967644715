import React from 'react';
import { motion } from 'framer-motion';
import { Box } from '@mui/material';

const Loading = () => {
  return (
    <motion.div
      initial={{ rotateY: 0 }}
      animate={{ rotateY: 360 }}
      transition={{
        duration: 2,
        ease: 'easeInOut',
        repeatDelay: 1,
        repeat: Infinity,
      }}
    >
      <Box>Something went wrong.</Box>
    </motion.div>
  );
};

export default Loading;
