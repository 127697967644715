import {parseISO, format, getTime, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fDt(date, newFormat) {
  const fm = newFormat || 'dd-MM-yyyy';

  return date ? format(new Date(`${date}T00:00:00`), fm) : '';
}

export const dfDate = () => {
  const ndate =new Date()
  const dateOnly = new Date(ndate.getFullYear, ndate.getMonth, ndate.getDate).toDateString();
  
  return (dateOnly);
};

export function formatDate(date){
  if (!date){
  const ndate =new Date()
  const dateOnly = new Date(ndate.getFullYear, ndate.getMonth, ndate.getDate).toDateString();
  return dayjs(dateOnly);
}
  return dayjs(date);
};

export function djangoDate(date){
  const fm = 'yyyy-MM-dd';
  return date ? format(new Date(date), fm) : '';
  
};
