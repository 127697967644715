const refkey = 'refed1bd7e-1d29-49a4';

const removeRefferal = () => {
  localStorage.removeItem(refkey);
};

export function setRefferal(refcode) {
    if (refcode){
        removeRefferal();
        localStorage.setItem(refkey, refcode);
    }
  
}

export function readRefferal() {
  const refcode = localStorage.getItem(refkey);
  if (refcode) {
    return refcode;
  } else {
    return '000';
  }
}
