import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';

// utils
import { imgLazy } from '../../../utils/imageLazy';

// data
import { usePartners } from '../../../apis/service/web/home';

//
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

PartnerCard.propTypes = {
  item: PropTypes.object,
  hasPremium: PropTypes.bool,
};

function PartnerCard({ item, hasPremium }) {
  const { img, name, url } = item;

  return (
    <Box
      component="img"
      alt={name}
      src={imgLazy(img)}
      sx={{ p: 1.2, width: '100%', height: hasPremium ? 180 : 120, objectFit: 'cover', borderRadius: 4 }}
    />
  );
}

// ----------------------------------------------------------------------
PartnerItem.propTypes = {
  items: PropTypes.object,
  index: PropTypes.number,
};

function PartnerItem({ items, index }) {
  const theme = useTheme();
  const { name, list } = items;

  const hasPremium = index === 0;

  return (
    <Grid container sx={{ py: theme.spacing(3) }}>
      <Grid item xs={12} md={3} lg={3}>
        <Typography
          variant="h4"
          noWrap
          sx={{ color: 'common.black', pt: { xs: theme.spacing(3), md: theme.spacing(6) } }}
        >
          {name}
        </Typography>
      </Grid>

      <Grid item xs={12} md={9} lg={9}>
        <Grid container>
          {list.map((item, inx) => (
            <Grid key={inx} item xs={hasPremium ? 12 : 6} sm={4} lg={hasPremium ? 4 : 2} sx={{ borderRadius: 4}}>
              <PartnerCard item={item} hasPremium={hasPremium} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
// ----------------------------------------------------------------------

export default function PartnerWidget() {
  const { data, isLoading, error } = usePartners();

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <>
      {data.map((partners, index) => (
        <PartnerItem key={index} items={partners} index={index} />
      ))}
    </>
  );
}
