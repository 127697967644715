import PropTypes from 'prop-types';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';
import Markdown from '../../../components/markdown';
// data
import { useMain } from '../../../apis/service/client/home';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  mx: 'auto',
  maxWidth: '100%',
}));
// ----------------------------------------------------------------------
MianWidget.propTypes = {
  shopcode: PropTypes.string,
};
export default function MianWidget({shopcode}) {
  const theme=useTheme();
  const { data, isLoading, error } = useMain(shopcode);

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <RootStyle>
      <Box mt={theme.spacing(4)}>      
      <Markdown>{data.body}</Markdown>
      </Box>
    </RootStyle>
  );
}
