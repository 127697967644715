import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Checkbox,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MIconButton } from '../../../components/@mui-extend';
// components
import Iconify from '../../../components/iconify';
//
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// data
import { useAuthMethods } from '../../../apis/service/common/authproviders';

import AuthWidget from './AuthWidget';

// ----------------------------------------------------------------------
function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
// ----------------------------------------------------------------------

function FetchAuthMethods() {
  const { data } = useAuthMethods();

  if (!data) {
    return [];
  }
  return data;
}


export default function LoginForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { login, isAuthenticated, status } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const authoptions = FetchAuthMethods();

  const [showPassword, setShowPassword] = useState(false);
  const [selectedAuth, setSelectedAuth] = useState('email');

  const FormSchema = Yup.object().shape({
    email: Yup.string().required('Email  is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: FormSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        let username = values.email;
        if (isNumeric(username)) {
          if (username.length === 10) {
            username = '91-' + username;
          }
        }

        await login(username, values.password);
        if (isAuthenticated) {
          enqueueSnackbar('Login success', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </MIconButton>
            ),
          });
        }

        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        // enqueueSnackbar('User name or password is incorrect.', {
        //   variant: 'error',
        //   action: (key) => (
        //     <MIconButton size="small">
        //       <Iconify icon={'eva:close-fill'} />
        //     </MIconButton>
        //   )
        //   });
        // console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.code || error.message });
        }
      }
      if (status === 403) {
        // enqueueSnackbar('You have no permission to access', {
        //   variant: 'error',
        //   action: (key) => (
        //    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        //      <Iconify icon={'eva:close-fill'} />
        //    </MIconButton>
        //  )
        //  });
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


  const handleSelectAuth = (value) => {
    setSelectedAuth(value);
  };



  const LoginForm = () => {
    return (
      <Stack spacing={2}>
        <TextField
          fullWidth
          autoComplete="email"
          type="text"
          label="Email address"
          {...getFieldProps('email')}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
          sx={{ mb: 3 }}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
          </LoadingButton>
      </Stack>
    );
  };


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          
          <Grid item xs={12}>
            <Typography
              variant="h3"
              noWrap
              sx={{ color: 'common.black', pt: 5, textAlign: 'left', textTransform: 'capitalize' }}
            >
              Log In
            </Typography>
            <Stack direction={'row'} spacing={1} pt={2} pb={1}>
              <Typography
                variant="h6"
                noWrap
                sx={{ color: 'common.black', textAlign: 'left', textTransform: 'capitalize' }}
              >
                Are you new user? 
              </Typography>

              <Typography
                variant="h6"
                component={RouterLink}
                to={'/auth/register'}
                noWrap
                sx={{ color: theme.palette.primary.main, textAlign: 'left', textTransform: 'capitalize' }}
              >
                Register
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <LoginForm />
          </Grid>
          
        </Grid>
      </Form>
    </FormikProvider>
  );
}

/*
<Grid item xs={12} md={2} alignContent={'center'}>
            <Divider variant="middle" orientation="vertical">
              <Typography
                variant="h6"
                noWrap
                sx={{ color: 'common.black', py: 5, textAlign: 'center', textTransform: 'uppercase' }}
              >
                or
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={4}>
            <AuthWidget authmethods={authoptions} selectedItem={selectedAuth} onHandle={handleSelectAuth} />
          </Grid>
*/