import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

export const CAROUSELS = [...Array(5)].map((_, index) => {
  const setIndex = index + 1;
  return {
    title: faker.person.jobTitle(),
    description: faker.lorem.paragraphs(),
    image: `/asset/images/covers/cover_${setIndex}.jpg`,
  };
});

export const TEAM = [...Array(4)].map((_, index) => {
  const setIndex = index + 1;
  return {
    id: faker.string.uuid(),
    name: faker.person.fullName(),
    role: faker.person.jobTitle(),
    phone: faker.phone.number(),
    whatsapp: faker.phone.number(),
    email: faker.internet.email(),
    avatar: `/asset/images/avatars/avatar_${setIndex}.jpg`,
  };
});

export const MAIN = {
  id: faker.string.uuid(),
  body: `<p>${faker.lorem.paragraphs({ min: 4, max: 6 })}</p>`,
};

export const ADVTBANNERS = [...Array(18)].map((_, index) => {
  const setIndex = index + 1;
  return {
    title: faker.person.jobTitle(),
    description: faker.lorem.paragraphs(),
    image: `/asset/images/covers/cover_${setIndex}.jpg`,
  };
});

const PRIZES = [
  {
    standing: 'First Prize',
    prize: 'Mobile Phone',
  },
  {
    standing: 'Second Prize',
    prize: 'Gold Coin',
  },
  {
    standing: 'Third Prize',
    prize: 'Smart watch',
  },
];

export const COUNTER = [
  {
    name: 'Page Visits',
    value: 323234,
    icon: 'line-md:emoji-grin',
    color: '#1877F2',
  }, // line-md:account
  {
    name: 'Shops',
    value: 341212,
    icon: 'line-md:play-twotone',
    color: '#DF3E30',
  },
  {
    name: 'Winners',
    value: 12,
    icon: 'line-md:question-circle',
    color: '#006097',
  },
  {
    name: 'Seasons',
    value: 1,
    icon: 'line-md:clipboard-check-twotone',
    color: '#1C9CEA',
  },
];

export const PARTNERS = [
  {
    name: 'Premium Partners',
    list: [...Array(3)].map((_, index) => ({
      id: faker.string.uuid(),
      image: `/asset/images/products/product_${index + 1}.jpg`,
      name: faker.company.name(),
    })),
  },
  {
    name: 'Partners',
    list: [...Array(18)].map((_, index) => ({
      id: faker.string.uuid(),
      image: `/asset/images/products/product_${index + 1}.jpg`,
      name: faker.company.name(),
    })),
  },
  {
    name: 'Sponsers',
    list: [...Array(18)].map((_, index) => ({
      id: faker.string.uuid(),
      image: `/asset/images/products/product_${index + 1}.jpg`,
      name: faker.company.name(),
    })),
  },
];
