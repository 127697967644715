// @mui
import { useTheme, alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Avatar, Typography, Stack } from '@mui/material';

// components

// ----------------------------------------------------------------------
const Benfits = [
  'Protect your clients’ sensitive data with free SSL certificates.',
  'Low cost Coupons',
  'Safeguard your personal information with robust privacy protection measure',
  'Defend your website against unwanted access with IP and country blocking, included with Hostinger C',
];
// ----------------------------------------------------------------------

export default function JoinUsWidget() {
  const theme = useTheme();
  const imagePath = `/asset/icons/others/tick.svg`;
  return (
    <>
      <Typography variant="h1" sx={{ py: 5, color: '#000', display: 'block', textAlign: 'center' }}>
        Join Us
      </Typography>

      <Grid container>
        <Grid item xs={12} md={6} py={theme.spacing(14)}>
          <Typography variant="h4" sx={{ py: 6, display: 'block', textAlign: 'center', textTransform: 'capitalize' }}>
            We makes Your sales High.
          </Typography>
          {Benfits.map((benfit, index) => (
            <Stack key={index} direction={'row'} spacing={2} sx={{ p: 2 }}>
              <Box component="img" alt={'Shop festia'} src={imagePath}  alignSelf={'left'}  sx={{ width: 50, height: 50, objectFit: 'scale-down' }} />
              
              <Typography variant="body1" sx={{ display: 'block' }}>
                {benfit}
              </Typography>
            </Stack>
          ))}
        </Grid>
        <Grid item xs={12} md={6} sx={{py:{md:theme.spacing(5), lg:theme.spacing(10)}, mt:{md:1, lg:theme.spacing(20)}}}>
          <Box
            component={'img'}
            src={'/asset/images/web/img_2.jpg'}
            sx={{ height: 'auto', objectFit: 'cover', borderRadius: 5, boxShadow: 'none' }}
          />
        </Grid>
      </Grid>
    </>
  );
}
