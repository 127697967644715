import PropTypes from 'prop-types';
import { useEffect } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, CardContent, Grid, Avatar, Typography, Stack } from '@mui/material';
// components
// components
import Error from '../../../components/error';
import Loading from '../../../components/loading';
import Label from '../../../components/label';
import Markdown from '../../../components/markdown';

// data
import { useFaqs } from '../../../apis/service/web/faq';

//
// ----------------------------------------------------------------------
const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingBottom: theme.spacing(8),
}));

// ----------------------------------------------------------------------

FaqCard.propTypes = {
  item: PropTypes.object,
};

function FaqCard({ item }) {
  const { question, answer, tags } = item;

  return (
    <CardContent sx={{ textAlign: 'left', m: 1 }}>
      <Typography variant="subtitle1" noWrap gutterBottom sx={{py:2}}>
        {question}
      </Typography>
      <Markdown>{answer}</Markdown>
      
      {/* <Stack direction="row" spacing={1}>
        {tags.map((tag, index) => (
          <Label key={index} variant="filled">
            {tag}
          </Label>
        ))}
      </Stack> */}
    </CardContent>
  );
}

// ----------------------------------------------------------------------
FaqListWidget.propTypes = {
  searchQuery: PropTypes.string,
};

export default function FaqListWidget({ searchQuery }) {
  const { data, isLoading, error, refetch } = useFaqs(searchQuery);

  useEffect(() => {
    refetch();
  }, [refetch, searchQuery]);

  if (!data && isLoading) {
    return <Loading />;
  }

  if (!data && error) {
    return <Error />;
  }
  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item xs={12}>
        <Typography variant="caption" noWrap gutterBottom>
          {data.length} results found.
        </Typography>
      </Grid>

      {data.map((faq) => (
        <Grid item xs={12}  key={faq.slug}>
          <FaqCard item={faq} />
        </Grid>
      ))}
    </Grid>
  );
}
