import { useQuery } from 'react-query';
import {Api} from '../../api';
import TNT_URLS from '../../urls/tweb.urls'; 


import {ADVTBANNERS} from '../../../_mock/client/home';

export const useMainBanner = (shopcode) => {
   return useQuery('tenent-mainbanner', async () => {
      const response = await  Api.unget(`${TNT_URLS.web.base.banner.main}${shopcode}/`);
      return response.data;
   });
  };

  export const useTeams = (shopcode) => {
   return useQuery('tenent-teams', async () => {
      const response = await  Api.unget(`${TNT_URLS.web.base.team.list}${shopcode}/`);
      return response.data;
   });
  };

  export const useAdvtBanners = () => {
   return useQuery('advtbanners', async () => {
      return ADVTBANNERS;
      // const response = await  Api.unget(TNT_URLS.banner);
      // return response.data;
   });
  };

  export const useMain = (shopcode) => {
   return useQuery('tenent-main', async () => {
      const response = await  Api.unget(`${TNT_URLS.web.base.main.default}${shopcode}/`);
      return response.data;
   });
  };

  export const useAbout = () => {
   return useQuery('tenent-about', async () => {
      const response = await  Api.unget(TNT_URLS.web.base.cms.about);
      return response.data;
   });
  };
