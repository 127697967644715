import {  styled } from '@mui/material/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';



const FTimePicker = styled(TimePicker)(({ theme }) => ({
   // Set your desired height
}));

export default  FTimePicker

// <TimePicker label="Controlled picker" value={value} onChange={(newValue) => setValue(newValue)} />

//   timezone="America/New_York"