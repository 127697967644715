import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// section components
import ScratchCardWidget from '../../sections/user/scratchcard/ScratchCardWidget';

// ----------------------------------------------------------------------

export default function ScratchcardPage() {
  return (
    <>
      <Helmet>
        <title> Scratch Cards | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <ScratchCardWidget />
      </Container>
    </>
  );
}
