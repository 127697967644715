import { useQuery } from 'react-query';
import {filter} from 'lodash';
import {Api} from '../../api';
import WEB_URLS from '../../urls/web.urls'; 


export const useGetBusinessByCode = (slug) => {
  return useQuery('web-business-bycode', async () => {
    const response = await Api.post(`${WEB_URLS.client.shop.shopbycode}${slug}/`);
    return response.data;
  });
};
