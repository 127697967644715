import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';

// APP Name
import { AppName } from '../../config';

// section widgets
import WinnerWidget from '../../sections/web/winner/winnerlist';

// ----------------------------------------------------------------------

export default function WinnerPage() {
  return (
    <>
      <Helmet>
        <title> Winner Gallery | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
        <WinnerWidget />
      </Container>
    </>
  );
}
