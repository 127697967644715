import { useQuery } from 'react-query';
import {filter} from 'lodash';
import {Api} from '../../api';
import WEB_URLS from '../../urls/web.urls'; 


export const useCoupons = () => {
  return useQuery('user-coupon-list', async () => {
     const response = await  Api.get(WEB_URLS.user.coupon.coupons);
     return response.data;
   });
 };

export const useCouponRequests = () => {
   return useQuery('user-couponrequest-list', async () => {
      const response = await  Api.get(WEB_URLS.user.coupon.requests);
      return response.data;
    });
  };

  export const useCouponBulkVerify = () => {
    return useQuery('client-coupon-bulk-verify', async () => {
       const response = await  Api.post(WEB_URLS.admin.coupon.request.verify);
       return response.data;
     });
   };
