import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';

// components
import ClientRegisterForm from '../../sections/web/register/ClientRegisterForm';

// APP Name
import { AppName } from '../../config';
// mock

// ----------------------------------------------------------------------

export default function RegisterPage() {
  return (
    <>
      <Helmet>
        <title>Shop Register | {AppName} </title>
      </Helmet>
      <Container maxWidth="xl">
      <ClientRegisterForm />
      </Container>
      
    </>
  );
}
