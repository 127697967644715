import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, TextField, Grid, Stack } from '@mui/material';
// components

// data
//
import ProductListWidget from './productlist';
// ----------------------------------------------------------------------
const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  paddingBottom: theme.spacing(8),
}));

const FormContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  minWidth: 250,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  my: 5,
  textDecoration: 'none',
  padding: theme.spacing(3),
}));
// ----------------------------------------------------------------------

FindProductWidget.propTypes = {
  shopcode: PropTypes.string,
};
export default function FindProductWidget({shopcode}) {
  const [searchText, setSearchText] = useState('');

  const handleInputSearch = (e) => {
    e.preventDefault();
    const query = e.target.value;
    setSearchText(query.toLowerCase());
  };

  return (
    <Grid container>
      <StyledContainer>
        <Grid item xs={12}>
        <Typography variant="h3" sx={{ color: '#000', display: 'block', textAlign: 'center', py:5 }}>
          Products and services
        </Typography>
        <FormContainer>
          <Stack direction={'row'} spacing={2}>
            <TextField fullWidth label="Search products" sx={{ mb: 3 }} onChange={handleInputSearch} />
          </Stack>
          <ProductListWidget shopcode={shopcode} searchQuery={searchText} />
        </FormContainer>
        </Grid>
      </StyledContainer>
    </Grid>
  );
}
