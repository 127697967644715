// material
import { enUS, deDE, frFR  } from '@mui/material/locale';

import { mlIN  } from './others';

const LANGS = [
    {
      label: 'English',
      value: 'en',
      systemValue: enUS,
      icon: '/assets/icons/ic_flag_en.svg'
    },
    {
      label: 'Deutsch',
      value: 'de',
      systemValue: deDE,
      icon: '/assets/icons/ic_flag_de.svg'
    },
    {
      label: 'French',
      value: 'fr',
      systemValue: frFR,
      icon: '/assets/icons/ic_flag_fr.svg'
    },
    {
      label: 'മലയാളം',
      value: 'ml',
      systemValue: mlIN,
      icon: '/assets/icons/ic_flag_in.svg'
    },
  ];
export default LANGS;