// AUTH

const API_V1 = '/api/v1/';

function ur(root, app, sublink) {
  return `${root}${app}${sublink}`;
}

const TNT_URLS = {
  web: {
    base: {
      main: {
        default: ur(API_V1, 'cb', '/sh/shop/default/'),
        themed: ur(API_V1, 'cb', '/sh/shop/themed/'),
      },
      banner: {
        main: ur(API_V1, 'cb', '/sh/banner/main/'),
      },
      team: {
        list: ur(API_V1, 'cb', '/sh/team/list/'),
      },
      item: {
        showcase: ur(API_V1, 'ims', '/sh/item/showcase/'),
        list: ur(API_V1, 'ims', '/sh/item/list/?q='),
        get: ur(API_V1, 'ims', '/sh/item/get/'), // slug
      },
      cms: {
        about: ur(API_V1, 'cb', '/sh/content/get/about-us/'),
      },
    },
    core: {
      list: ur(API_V1, 'cb', '/sh/team/list/'),
    },
    coupon: {
      request: {
        add: ur(API_V1, 'cp', '/sh/req/add/'),
        pinverify: ur(API_V1, 'cp', '/sh/req/verifypin/'),
      },
      liveqr: {
        token: ur(API_V1, 'cp', '/sh/live/token/'), //id-slug, scancode
        add: ur(API_V1, 'cp', '/sh/live/add/'), //id-slug, scancode , name, ph
      },
    },
  },
  admin: {
    dashboard: {
      currentplan: 'xxxxxxxxxxx',
      counter: 'xxxxxxxxxxxxxxx',
    },
    coupon: {
      csv: { upload: ur(API_V1, 'cp', '/ad/csv/upload/') },
      request: {
        list: ur(API_V1, 'cp', '/ad/req/list/'),
        verify: ur(API_V1, 'cp', '/ad/req/verify/'), // bulk verify
        generate: ur(API_V1, 'cp', '/ad/req/generate/'),
      },
      liveqr: {
        qr: ur(API_V1, 'cp', '/ad/live/qr/'),
        api: ur(API_V1, 'cp', '/ad/live/api/'),
      },
    },
    cms: {
      banner: {
        list: ur(API_V1, 'cb', '/ad/banner/list/'),
        get: ur(API_V1, 'cb', '/ad/banner/get/'),
        add: ur(API_V1, 'cb', '/ad/banner/add/'),
        edit: ur(API_V1, 'cb', '/ad/banner/edit/'),
      },
      team: {
        list: ur(API_V1, 'cb', '/ad/team/list/'),
        get: ur(API_V1, 'cb', '/ad/team/get/'), 
        add: ur(API_V1, 'cb', '/ad/team/add/'),
        edit: ur(API_V1, 'cb', '/ad/team/edit/'),
        del: ur(API_V1, 'cb', '/ad/team/del/'),
      },
      content: {
        list: ur(API_V1, 'cb', '/ad/content/list/'),
        get: ur(API_V1, 'cb', '/ad/content/get/'),
        add: ur(API_V1, 'cb', '/ad/content/add/'),
        edit: ur(API_V1, 'cb', '/ad/content/edit/'),
      },
      item: {
        list: ur(API_V1, 'cb', '/ad/item/list/'),
        get: ur(API_V1, 'cb', '/ad/item/get/'),
        add: ur(API_V1, 'cb', '/ad/item/add/'), // cb/ad/item/add/
        edit: ur(API_V1, 'cb', '/ad/item/edit/'),
        del: ur(API_V1, 'cb', '/ad/item/del/'),
      },
      material: {
        list: ur(API_V1, 'cc', '/ad/material/list/'),
      },
    },
    shop: {
      get: ur(API_V1, 'cb', '/ad/shop/get/'),
      edit: ur(API_V1, 'cb', '/ad/shop/edit/'),
    },
  },
};

export default TNT_URLS;
