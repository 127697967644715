import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { useTheme, styled, alpha } from '@mui/material/styles';
import { Box, Stack, Link, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
// hooks
import useAuth from '../../../hooks/useAuth';
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
//
import navConfig from './config';
// ----------------------------------------------------------------------

const HEADER_MOBILE = 34;

const HEADER_DESKTOP = 52;

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  color: theme.palette.text.primary,
  position: 'absolute',
  padding: theme.spacing(2, 2, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3, 3, 0),
  },
}));

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  padding: theme.spacing(3, 1),
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(3, 6),
  },
}));

const StyledTitle = styled(Link)(({ theme }) => ({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  textTransform: 'capitalize',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  padding: theme.spacing(0, 2),
  textDecoration: 'none',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 3),
  },
  '&:hover': {
    textDecoration: 'none',
    color: 'gray',
  },
}));

const StyledScan = styled(Link)(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: alpha(theme.palette.grey[900], 0.92),
  textTransform: 'capitalize',
  cursor:'pointer',
  textDecoration: 'none',
  color: 'white',
  py: 1,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1.5, 3),
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[700], 0.92),
  },
}));

// ----------------------------------------------------------------------
Header.propTypes = {
  onOpenNav: PropTypes.func,
};
// ----------------------------------------------------------------------
export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const isDesktop = useResponsive('up', 'lg');

  return (
    <StyledRoot>
      <StyledHeader>
        <IconButton
          onClick={onOpenNav}
          sx={{
            color: 'text.primary',
            display: { lg: 'none' },
            height:64,
            width: 64
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Logo ex='s-' sx={{ mt:1.7, width: 'auto', height:45 }} />
      </StyledHeader>

      {isDesktop && (
        <StyledToolbar>
          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            {navConfig.map((menu, index) => (
              <StyledTitle
                key={index}
                color="inherit"
                variant="subtitle1"
                underline="hover"
                component={RouterLink}
                to={menu.path}
                sx={{
                  color: 'common.black',
                }}
              >
                {menu.title}
              </StyledTitle>
            ))}
          </Stack>
          <Box sx={{ flexGrow: 0.3 }} />
          {!isAuthenticated && (
            <Stack direction="row" spacing={theme.spacing(0.5)}>
              <StyledScan component={RouterLink} to={'/auth/login'}>
                Login
              </StyledScan>
              <StyledScan  component={RouterLink} to={'/auth/register'}>
                Register
              </StyledScan>
            </Stack>
          )}
        </StyledToolbar>
      )}
    </StyledRoot>
  );
}
